import React, {FC, ReactElement, useEffect, useState} from 'react';
import { useFaunaContext } from 'Models/Fauna';
import { useAlert } from 'react-alert';
import { useItemStore } from 'cogint-item-providers-react';
import { loadDashboardItems } from '../Dashboard';
import { translateFromFauna } from 'cogint-item-fauna-middleware';
import { useMyIdentityContext } from 'Models/MyIdentity';
import Skeleton from "react-loading-skeleton";
import { Intelephant } from 'Views/Components/Logos';
import { useTheme } from 'Views/Theme';
import { ItemI } from 'cogint-item-fundaments';
import { DomainDocI, DomainFromItemRef, Items } from 'cogint-fauna';
import {query as q, values} from "faunadb";
import { useNavigate } from 'react-router-dom';

export const DomainTagController : FC<{
    domain : ItemI
}> = ({domain})=>{

    const {primaryColor, tertiaryColor} = useTheme();

    const {faunaClient} = useFaunaContext();

    const [domainDoc, setDomainDoc] = useState<DomainDocI|undefined>(undefined);
    useEffect(()=>{
        faunaClient && faunaClient.query<DomainDocI>(DomainFromItemRef(q.Ref(Items(), domain._id) as values.Ref))
        .then((data)=>setDomainDoc(data))
    }, [faunaClient]);


    const nav = useNavigate();

    const handleClick = ()=>{
        domainDoc && nav(`/app/d/${domainDoc.ref.id}/dashboard`);
    }

    return (
        <div 
        className="hover-invert-sm shadow-sm"
        onClick={handleClick}
        style={{
            width : "100%",
            cursor : "pointer",
            padding : "20px",
            border : `1px solid ${domainDoc ? domainDoc.data.color : primaryColor}`,
            borderRadius : "10px"
        }}>
           {domainDoc ? 
             <><h4>{domain.name}</h4>
             <span style={{
                 color : tertiaryColor
             }}>{domainDoc.ref.id}</span></>
             : <Skeleton/>   
            }
        </div>
    )

}

export type AppHomeProps = {}

export const AppHome : FC<AppHomeProps>  = () =>{

    const alert = useAlert(); 
    const {
        setItems,
        selectItems
    } = useItemStore();

    const {primaryColor, secondaryColor, tertiaryColor} = useTheme();
    
    const {
        faunaClient,
        setFauna
    } = useFaunaContext();
    useEffect(()=>{
       if(faunaClient)
            loadDashboardItems(faunaClient)
            .then((data)=>{
                setItems((items)=>{
                    return data.data.reduce((agg, item)=>{
                            const _item = translateFromFauna(item); 
                            return {
                                ...agg,
                                [_item._id] : _item
                            } 
                        }, items)
                });
                alert.success(`Loaded ${Object.keys(data.data).length} items.`)
            })
            .catch((err: Error)=>{
                alert.error(err.message);
            })
    }, [faunaClient]);

    const {myIdentity} = useMyIdentityContext();

    const domains = selectItems((items)=>{
        return Object.keys(items).reduce((agg, key)=>{
            return {
                ...agg,
                ...items[key].itemType === "Domain" ? 
                {
                    [key] : items[key]
                } : {}
            }
        }, {})
    });



    return (

        <div style={{
            width : "100vw",
            height : "100vh",
            display : "grid",
            justifyItems : 'center',
            justifyContent : "center",
            color : tertiaryColor
        }}>
            <div style={{
                width : "60vw",
                padding : "20px",
                textAlign : "left"
            }}>
                <div style={{
                    display : "flex",
                    alignContent : "center",
                    alignItems : "center"
                }}>
                    <Intelephant color={primaryColor}/>
                    &ensp;
                    <div>
                    {myIdentity ? <h4>Welcome, {myIdentity.data.email}!</h4> : <Skeleton/>}
                    </div>
                </div>
                <hr/>
                <h4 id="your-domains">Your Domains <a style={{
                    color : "pink"
                }} href="#your-domains">#</a></h4>
                <div style={{
                    display : "grid",
                    gap : "5%"
                }}>
                    {Object.keys(domains).map((key)=>{
                        return <DomainTagController domain={domains[key]}/>
                    })}
                </div>
            </div>
        </div>

    )

}