import React, {FC, ReactElement} from 'react';
import { ToSContextController } from './ToSContextController';
import { ToSGuardController } from "./ToSGuardController";

export type ToSControllerProps = {}

export const ToSController : FC<ToSControllerProps>  = ({children}) =>{

    return (

        <ToSContextController>
            <ToSGuardController>
                {children}
            </ToSGuardController>
        </ToSContextController>

    )

}