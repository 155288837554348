import React, {FC, ReactElement} from 'react';
import {Button, ButtonProps} from "cntric-component-library";
import { useTheme } from 'Views/Theme';
import { useFaunaContext } from 'Models/Fauna';
import { CogintTokenDocI, IssueCogintToken, Items } from 'cogint-fauna';
import { ItemI } from 'cogint-item-fundaments';
import { values, query as q } from 'faunadb';
import { useState } from 'react';

export type RoleConfigInviteByEmailProps = ButtonProps & {
    text : string,
    item : ItemI
}

export const isValidEmail = (email : string)=>{
    return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
}

export const RoleConfigInviteByEmail : FC<RoleConfigInviteByEmailProps>  = (props) =>{

    const {secondaryColor} = useTheme();
    const {faunaClient} = useFaunaContext();
    const [token, setToken] = useState<string|undefined>(undefined);

    const handleClick = async ()=>{
        if(faunaClient){
            const result = await faunaClient.query<CogintTokenDocI>(IssueCogintToken(
                q.Ref(Items(), props.item._id) as values.Ref,
                props.text
            ))
            setToken(result.ref.id);
        }
    }

    return (
        <>
            <Button {...{props}} 
            action={handleClick}
            disabled={!isValidEmail(props.text)}
            secondaryColor={secondaryColor}
            primaryColor={isValidEmail(props.text) ? "green" : "salmon"}>
                <div style={{
                    display : "flex"
                }}>
                    Invite by email: {props.text}
                </div>
            </Button>
            {token && <span><span style={{
                color : "gray"
            }}>A token has been sent: </span><a href={`${window.location.origin}/app/accept/${token}`}>{`${window.location.origin}/app/accept/${token}`}</a></span>}
        </>
        

    )

}