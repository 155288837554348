import React, { createContext, useReducer, FC } from "react";
import { ItemStoreI, ItemStoreProvider } from "cogint-item-providers-react";
import { useItemStore, ItemProvider } from "./ItemStoreProvider";

export const InnerProvider : FC = ({children})=>{
    
    const {
        items,
        dispatch
    } = useItemStore()

    const selectItems = (select ? : (items : ItemStoreI)=>ItemStoreI)=>{
        
        return select ? select({
            ...items
        }) : {
            ...items
        };
    };
    const setItems = (set : (items : ItemStoreI)=>ItemStoreI)=>dispatch((state)=>{

        return {
            ...state,
            items : set(state.items)
        }
    });

    

    return <ItemStoreProvider 
        selectItems={selectItems}
        setItems={setItems}>
        {children}
    </ItemStoreProvider>
}


export const ItemStoreController : FC = ({children})=>{
    return (
        <ItemProvider>
            <InnerProvider>
                {children}
            </InnerProvider>
        </ItemProvider>
    )
}