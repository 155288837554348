import React, {FC, ReactElement} from 'react';
import {ItemI} from "cogint-item-fundaments";
import { itemReducer, useItemStore } from 'cogint-item-providers-react';
import { filterBySearch } from 'Models/lib';
import { useState } from 'react';
import { Button, Searchbar } from 'cntric-component-library';
import { CogintUpdate, ContainerDocI, ContainerFromItemRef, Items, UpdateContainerDoc } from 'cogint-fauna';
import { useEffect } from 'react';
import { useFaunaContext } from 'Models/Fauna';
import {query as q, values} from "faunadb";
import { useTheme } from 'Views/Theme';
import {to} from "await-to-js";

export type ContainerToggleProps = {
    containerItem : ItemI,
    item : ItemI
}

export const ContainerToggle : FC<ContainerToggleProps> = ({containerItem, item})=>{

    const {faunaClient} = useFaunaContext();
    const {primaryColor, secondaryColor} = useTheme();

    const [container, setContainer] = useState<ContainerDocI|undefined>();
    useEffect(()=>{
        faunaClient && faunaClient.query<ContainerDocI>(ContainerFromItemRef(q.Ref(Items(), containerItem._id) as values.Ref))
        .then((containerDoc)=>setContainer(containerDoc));
    }, [faunaClient])

    const addToContainer = async ()=>{
        if(container && faunaClient){
            const [err, result] = await to(faunaClient.query<ContainerDocI>(UpdateContainerDoc(
                container.ref,
                {
                    items : q.Distinct(q.Append(
                        [q.Ref(Items(), item._id)],
                        q.Select(["data", "items"], q.Get(container.ref))
                    )) as values.Ref[]
                }
            )));
            result && setContainer(result);
        }
    };
    
    const removeFromContainer = async ()=>{
        if(container && faunaClient){
            const [err, result] = await to(faunaClient.query<ContainerDocI>(UpdateContainerDoc(
                container.ref,
                {
                    items : q.Distinct(
                        q.Filter(
                            q.Select(["data", "items"], q.Get(container.ref)),
                            q.Lambda(
                                "ref",
                                q.Not(q.Equals(q.Ref(Items(), item._id), q.Var("ref")))
                            )
                        )
                    ) as values.Ref[]
                }
            )));
            result && setContainer(result);
        }
    };

    const handleClick = async ()=>{
        container && container.data.items.map(item=>item.id).includes(item._id) ?
        await removeFromContainer() : await addToContainer();
    }

    return (
        <Button
            action={handleClick}
            invert={container && container.data.items.map(item=>item.id).includes(item._id)}
            secondaryColor={secondaryColor}
            primaryColor={container ? container.data.color : primaryColor}>
            {containerItem.name}
        </Button>
    )

}

export type AddContainerControllerProps = {
    item : ItemI
}

export const AddContainerController : FC<AddContainerControllerProps>  = ({item}) =>{

    const {primaryColor, backgroundColor} = useTheme();

    const {selectItems} = useItemStore();
    const availableContainers = selectItems((items)=>{
        return Object.keys(items).reduce((agg, key)=>{
            return {
                ...agg,
                ...items[key].itemType === "Container" ? {
                    [key] : items[key]
                } : {}
            }
        }, {})
    });

    

    const [search, setSearch] = useState("");
    const filteredContainers = filterBySearch(
        availableContainers, 
        search,
        (item)=>`${item.name}:${item.itemType}`
    );

    

    return (

        <div style={{
            backdropFilter : "invert(5%)",
            background : backgroundColor,
            width : "500px",
            borderRadius : "5px",
            padding : "10px"
        }}>
            <Searchbar 
            primaryColor={primaryColor}
            secondaryColor={backgroundColor}
            onChange={()=>{
                setSearch(search)
            }}/>
            <br/>
            <div style={{
                display : "grid",
                gridTemplateColumns : "1fr 1fr 1fr",
                gap : "2%"
            }}>
                {Object.values(filteredContainers).map((container)=>{
                   return <ContainerToggle containerItem={container} item={item}/>
                })}
            </div>
        </div>

    )

}