import Color from "color";
import { Button } from "cntric-component-library";
import React, { FC, useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { useTheme } from "Views/Theme";

export type TabProps = {
    name: string,
    index: number,
    style?: React.CSSProperties,
    id: string,
    action?: (index: number)=>Promise<void>,
    onClick?: (tabName : string, index: number)=>void,
    handleClose?: (e : React.MouseEvent, tabName : string, index: number)=>void
}

export const Tab : FC<TabProps>  = ({
    name,
    index,
    style, 
    children,
    id,
    onClick=(tabName : string, index: number)=>{}, 
    action=async (index : number)=>{},
    handleClose=(e : React.MouseEvent, tabName : string, index: number)=>{}
}) =>{

    const {
        primaryColor,
        secondaryColor,
        backgroundColor,
        IconSet
    } = useTheme();

    const {
        Close
    } = IconSet;

    const [closeHovered, setCloseHovered] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);

    return (
        <div style={{
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            paddingLeft: "14px",
            paddingRight: "14px",
            backgroundColor: closeHovered ? 
                                Color(backgroundColor).mix(Color("salmon").lighten(.3)).hex() 
                                : buttonHovered ? 
                                    primaryColor
                                    : backgroundColor,
            ...style,
        }}>
            <div id={id} style={{
                display: "grid",
                gridTemplateColumns: "3fr 1fr",
                alignItems: "center"
            }}> 
                <Button 
                    onMouseEnter={()=>{    
                        
                        setButtonHovered(true)
                    }}
                    onMouseLeave={()=>{
                        setButtonHovered(false);
                    }}
                    onClick={()=>{
                        onClick(name, index);
                    }}
                    action={async ()=>{
                        await action(index);
                    }}
                    style={{
                        color: buttonHovered ? secondaryColor : primaryColor,
                        width: "120px",
                        height: "40px",
                        paddingLeft: "0px",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflowWrap: "anywhere",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        fontSize: "12px",
                        outline: "none",
                        border: "none",
                        backgroundColor: "#00000000"
                    }} size="sm">
                    {children}
                </Button>
                <Close 
                    onMouseEnter={()=>{
                        setCloseHovered(true);
                    }}
                    onMouseLeave={()=>{
                        setCloseHovered(false);
                    }}
                    onClick={(e : React.MouseEvent)=>{
                        handleClose(e, name, index);
                    }}
                    size={16} style={{
                        cursor: "pointer",
                        color: closeHovered ? 
                            "salmon" 
                            : buttonHovered ? 
                                secondaryColor
                                : primaryColor
                    }}/>
            </div>
        </div>
                       
    )
}
