import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { useFaunaContext } from 'Models/Fauna';
import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useReducer } from 'react';
import { useContext } from 'react';
import { useTheme } from 'Views/Theme';

export type DashboardContext = {
    /** the mdoe that the dashboard is in. */
    mode : string,
    /** dispatch. */
    dispatch : React.Dispatch<(state: DashboardContext) => DashboardContext>
}

/**
 * By default the dashboard name should be root
 * and the views should be empty.
 */
export const DefaultDashboardContext : DashboardContext = {
   mode : "Stakeholder",
   dispatch : ()=>{}
} 

export const DashboardCtx = createContext(DefaultDashboardContext);

/**
 * Reduces the state of a dashboard with a setter function.
 * @param state is the state of the dashboard before the reductino.
 * @param set is a function that sets the state of the dashboard.
 * @returns the new state of the dashboard.
 */
export const dashboardReducer = (state : DashboardContext, set : (state : DashboardContext)=>DashboardContext)=>set(state);

export type DashboardContextProps = {

}

/**
 * Provides access to DashboardContext. Handles view fetching for DashboardContext.
 * @param param0 
 * @returns 
 */
export const DashboardContextProvider : FC<DashboardContextProps>  = ({children}) =>{

    const [state, dispatch] = useReducer(
        dashboardReducer,
        DefaultDashboardContext
    );

    const {viewNames} = useDomainContext();
    
    useEffect(()=>{
        
        if(!viewNames.includes(state.mode) && viewNames.length) dispatch((state)=>({
            ...state,
            mode  : viewNames[0]
        }))
    }, [viewNames])

    return (

        <DashboardCtx.Provider value={{
            ...state,
            dispatch
        }}>
            {children}
        </DashboardCtx.Provider>

    )

}

/**
 * Gives read-only access to dashboard context.
 * @returns the current dashboard context.
 */
export const useDashboardContext = () : Readonly<DashboardContext> =>{
    const context = useContext(DashboardCtx);
    if(!context) throw new Error("useDashboardContext must be called from within a context provider.");
    return context;
}

export const useDashboardMode = () : Readonly<{
    mode : string, 
    setMode : (mode : string)=>void
}>=>{
    const context = useContext(DashboardCtx);
    if(!context) throw new Error("useDashboardMode must be called from within a a DashboardContextProvider.");
    return {
        mode : context.mode,
        setMode : (mode : string)=>context.dispatch((state)=>({...state, mode : mode}))
    }
}