import { Dashboard } from "Controllers/Pages/Dashboard";
import { LandingPage } from "Controllers/Pages/LandingPage";
import React, { FC } from 'react';
import { Route, Routes } from "react-router-dom";
import {App} from "Controllers/Segments/App"
import { TermsOfUse } from "Controllers/Pages/TermsOfUse/TermsOfUse";
import { EditViews } from "Controllers/Pages/EditViews";
import { DomainRouter } from "./DomainRouter";
import { AcceptCogintToken, AcceptCogintTokenFunctionName } from "cogint-fauna";
import { AcceptTokenController } from "Controllers/Token/IntegratedAcceptToken";
import { AppHome } from "Controllers/Pages/AppHome/AppHome";

export type AppRouterProps = {}

export const AppRouter : FC<AppRouterProps>  = () =>{

    return (
        <App>
            <Routes>
                <Route path="" element={<AppHome/>}/>
                <Route path="d/:domain/*" element={<DomainRouter/>}/>
                <Route path="terms" element={<TermsOfUse/>}/>
                <Route path="accept/:tokenId" element={<AcceptTokenController/>}/>
            </Routes>
        </App>

       
       
    )

}