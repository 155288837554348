import React, { createContext, useReducer, FC } from "react";
import { ItemStoreI, synthesizeDirs } from "cogint-item-providers-react";
import { useContext } from "react";

interface ItemsStoreContextI {
    items : ItemStoreI,
    dispatch : React.Dispatch<(items: ItemsStoreContextI) => ItemsStoreContextI>
}
const ItemsStore : React.Context<ItemsStoreContextI> = createContext({
    items : {},
    dispatch : ()=>{}
} as ItemsStoreContextI);
const itemsReducer = (state : ItemsStoreContextI, set : (items : ItemsStoreContextI)=>ItemsStoreContextI)=>set(state);
/*{    return {
        ...state,
        items : synthesizeDirs(_set.items)
    };
};*/
export const ItemProvider : FC  = ({children})=>{
    
    const [state, dispatch] = useReducer(itemsReducer, {
        items : {},
        dispatch : ()=>{}
    });

    return <ItemsStore.Provider value={{
        ...state,
        dispatch : dispatch
    }}>
        {children}
    </ItemsStore.Provider>

}

export const useItemStore = () : ItemsStoreContextI=>{

    const context = useContext(ItemsStore);
    if(!context){
        throw new Error("useItemStore must be called within a ItemProvider.");
    }
    return context;

}
