/**
 * @initialAuthor Liam Monninger (l-monninger)
 * @contributors
 * Liam Monninger (l-monninger)
 * @purpose This component is used to manage the layouts of the site.
 */
import React, { Children, FC } from 'react';
import MediaQuery from "react-responsive";
import {
    Content
} from "./Content";
import {
    Footer
} from "./Footer";
import {
    Header
} from "./Header";
import {
    SideNav
} from "./SideNav";
import { Resizable } from "re-resizable";
import {useEffect, useReducer, useRef } from 'react';
import {
    useTheme
} from "Views/Theme";
import { LayoutProvider, useLayoutContext } from './LayoutContext';
import { generateNamedMember, getComponentMembers } from 'rgfm';



export const Components = ["Header", "Content", "SideNav", "Footer"] as const;
export type Component = typeof Components[number];

/**
 * @description gets the ItemVariant for a given mode.
 * @param mode is the mode in which the Item is being viewd.
 * @param children are the children of the Item, amongst witch variant nodes will be identified.
 * @returns the children which match the display mode.
 * @error if there are duplicate definitions of the variant.
 */
const getLayoutComponent = (component : Component, children : React.ReactNode)=>{

    if(!children){
        return;
    }

    const matchingItems = children ? 
        Children.toArray(children).filter((child)=>{
            try {   
                return (child as any).type.name === component;
            } catch(e){
                return false;
            }
        }) 
        : "none"

    if(matchingItems.length > 1) {
        throw new Error(`You have provided more than one Item.${component}. If you are working in the broswer, you may need to refresh to see your changes.`)
    }

    return matchingItems[0];

}



/**
 * @description
 */
export type LayoutProps = {}


const InnerLayout : FC<LayoutProps> = ({children})=>{

    const [tick, forceUpdate] = useReducer(x=>x+1, 0);

    const {
        displayContent,
        displayFooter,
        displayHeader,
        displaySideNav,
        HeaderRef,
        dispatch
    } = useLayoutContext();

    const {
        Header,
        Content,
        SideNav,
        Footer
    } = getComponentMembers(Components, children);

    const headerRef = useRef<HTMLDivElement>(null);

    const updateHeaderRef = ()=>{
        dispatch({
            type : "def",
            payload : {
                HeaderRef : headerRef
            }
        })
    }

    const {
        backgroundColor
    } = useTheme();

    useEffect(()=>{

    if(HeaderRef.current?.id !== headerRef.current?.id){

            dispatch({
                type : "def",
                payload : {
                    HeaderRef : headerRef
                }
            })
        }

    })

    
    


    return (
            <div style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                backgroundColor: backgroundColor
            }}>
                <Resizable
                    maxHeight={Footer && displayFooter ? "97%" : "100%"}
                    style={{
                        display: "flex",
                        flex: 2
                    }}
                >{/**SideNav, Header, Content */}
                    {SideNav&&displaySideNav&& <Resizable 
                        minWidth="20%"
                        maxWidth="50%"
                        defaultSize={{
                            width: "25%",
                            height: "100%"
                        }}>{/**SideNav */}
                        {SideNav}
                    </Resizable>}
                    <Resizable 
                        handleStyles={{
                            "left" : {
                                display: "none",
                            },
                            "bottom" : {
                                display : "none"
                            }
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexBasis: 0,
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                        minWidth="50%"
                        maxWidth={SideNav && displaySideNav ? "90%" : "100%"}
                        defaultSize={{
                            height: "100%",
                            width: SideNav && displaySideNav ? "90%" : "100%"
                        }}>
                        {Header && displayHeader&& <div
                            id={`header@${tick}`}
                            ref={headerRef}><Resizable 
                            enable={{
                                top : false,
                                right : false,
                                bottom : false,
                                left : false,
                                topRight : false,
                                bottomRight : false,
                                bottomLeft : false,
                                topLeft : false
                            }}
                            onResize={updateHeaderRef}
                            className="shadow"
                            minHeight="65px"
                            maxHeight="12vh"
                            defaultSize={{
                                height: "65px",
                                width: "100%"
                            }}
                            style={{
                                zIndex: 1000,
                                flex: 2,
                                margin: "none"
                            }}>{/**Header */}
                            {Header}
                        </Resizable></div>}
                        <Resizable 
                            enable={{
                                right : false,
                                topRight : false,
                                bottomRight : false
                            }}
                            handleStyles={{
                                "left" : {
                                    display: "none",
                                },
                                "top" : {
                                    display : "none"
                                }
                            }}
                            
                            style={{
                                margin: "none",
                                flex: 2,
                                overflowY: "scroll",
                                scrollbarWidth : "thin",
                                scrollbarColor : "gray",
                                overflowX: "hidden",
                            }}>{/**Content */}
                           {Content||<></>}
                           <br/>
                           <br/>
                        </Resizable>
                    </Resizable>
                </Resizable>
                {Footer && displayFooter&& <Resizable 
                    maxHeight="5vh"
                    minHeight="3vh"
                    handleStyles={{
                        "bottom" : {
                            display : "none"
                        }
                    }}
                    style={{
                        flex: 2
                }}>{/**Footer */}
                    {Footer}
                </Resizable>}
            </div>
        )
}

const Layout : FC<LayoutProps> & {
    Header : FC<any>,
    Content: FC<any>,
    SideNav : FC<any>
    Footer: FC<any>
} = ({children})=>{
    return <LayoutProvider>
        <InnerLayout>
            {children}
        </InnerLayout>
    </LayoutProvider>
}

// For child parsing
Layout.Header = generateNamedMember("Header");
Layout.Content = generateNamedMember("Content");
Layout.SideNav = generateNamedMember("SideNav");
Layout.Footer = generateNamedMember("Footer");

export { Layout };
