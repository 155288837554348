import React, {FC, ReactElement} from 'react';
import {AddGoogleDoc} from "cogint-add-gdoc-widget";
import { useTheme } from 'Views/Theme';
import { Item, ItemI } from 'cogint-item-fundaments';
import { generate } from 'shortid';
import { useGapi } from 'hapi-gapi-toolkit';
import {Client, CurrentIdentity, values} from "faunadb";
import { CheckPrivilege, MyIdentity, DocDocI, NewDoc, } from 'cogint-fauna';
import { CommitMethodI } from 'cogint-react-item-namespace';
import { useItemStore, commit } from 'cogint-item-providers-react';
import { useFaunaContext } from 'Models/Fauna';
import { useItemSlotsContext } from 'Models/ItemSlots';

export const addDoc = async (
    client : Client,
    lookup : string, 
    name : string,
    commit : CommitMethodI,
    addToSlot : (item : ItemI)=>void
)=>{

    const doc =  await client.query<DocDocI>(NewDoc({
        name : name,
        lookup : lookup
    }));

    await commit(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Doc"
    }));

    addToSlot(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Doc"
    }));


}

export const AddGoogleDocItem = Item({
    _id : "pick-google-doc",
    name : "widgets/Pick Google Doc",
    itemType : "Widget"
})

export type AddDocControllerProps = {
    item : ItemI
}

export const AddDocController : FC<AddDocControllerProps>  = () =>{

    const {
        primaryColor,
        secondaryColor,
        tertiaryColor
    } = useTheme();

    const {
        client,
        drivers,
        handleSignInClick,
        gapiAuthorized,
        gapiLoaded,
        gapiSigningIn
    } = useGapi();

    const storeContext = useItemStore();
    const {faunaClient} = useFaunaContext();
    const _commit = async (item : ItemI)=>{
        await commit(storeContext, {[item._id] : item});
    }

    const {
        setItemSlots,
    } = useItemSlotsContext();

    const addSlots = (item : ItemI)=>{
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlot : {
                    ...slots.mainSlot,
                    [item._id] : item
                }
            }
        })
    }

    const _addDoc = (lookup : string, name : string)=>{
        faunaClient && addDoc(
            faunaClient,
            lookup,
            name,
            _commit,
            addSlots
        )
    }


    return (

        <AddGoogleDoc
        size={30}
        addDoc={_addDoc}
        picker={drivers.picker ? drivers.picker.api : undefined}
        apiKey={process.env.REACT_APP_GAPI_SECRET as string}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        client={client}
        authorized={gapiAuthorized}
        gapiLoaded={gapiLoaded}
        signingIn={gapiSigningIn}
        handleSignInClick={handleSignInClick}/>

    )

}