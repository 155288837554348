import React, {FC, ReactElement} from 'react';
import {Routes, Route} from "react-router-dom";
import { EditViews } from 'Controllers/Pages/EditViews';
import { TermsOfUse } from 'Controllers/Pages/TermsOfUse/TermsOfUse';
import { AgreeTermsOfUse } from 'Controllers/Pages/TermsOfUse/AgreeTermsOfUse';

export type EditRouterProps = {}

export const EditRouter : FC<EditRouterProps>  = () =>{

    return (

        <Routes>
            <Route path="views" element={<EditViews/>}/>
            <Route path="tos"  element={<AgreeTermsOfUse/>}/>
        </Routes>

    )

}