import { useAuth0 } from "@auth0/auth0-react";
import { ArbitraryStoreProvider } from "Models/ArbitraryStore";
import {GapiProvider} from "hapi-gapi-toolkit";
import {ItemStoreProvider} from "cogint-item-providers-react";
import React, { FC } from 'react';
import { ItemStoreController } from "Models/ItemStore";
import { ItemSlotsProvider } from "Models/ItemSlots";
import { FaunaProvider } from "Models/Fauna";
import LoadingSkeleton from "react-loading-skeleton";
import { Spinner } from "react-bootstrap";
import { DomainController } from "Controllers/Domain";
import { Auth0Controller } from "Controllers/Auth0";
import { ToSController } from "Controllers/ToS";
import { LoadingPage } from "Controllers/Pages/LoadingPage";
import { MyIdentity } from "cogint-fauna";
import { MyIdentityController } from "Controllers/MyIdentityController";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type AppProps = {}

/**
 * @description 
 */
export const App : FC<AppProps>  = ({children}) =>{

    const auth0 = useAuth0();

    

    if(!auth0.isAuthenticated && !auth0.isLoading)
        auth0.loginWithRedirect({appState : {
            returnTo : window.location.pathname + window.location.search
        }})

    if(auth0.isLoading && !auth0.isAuthenticated) return <LoadingPage/>
    
    
    return (


        <GapiProvider
        apiKey={process.env.REACT_APP_GAPI_SECRET as string}
        clientId={process.env.REACT_APP_GAPI_CLIENT_ID as string}
        scopes={"https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive.file"}
        gapiDrivers={[
            {
                name: "docs",
                version: "v1",
                status: "loading",
                as: "docs"
            },
            {
                name: "picker",
                status: "loading",
                as: "picker"
            },
            {
                name : "gapi.iframes",
                status : "loading",
                as : "gapiIframes"
            }
        ]}>
            <ItemStoreController>
                <ItemSlotsProvider>
                    <FaunaProvider>
                        <DomainController>
                            <ToSController>
                                <MyIdentityController>
                                    {children}
                                </MyIdentityController>
                            </ToSController>
                        </DomainController>
                    </FaunaProvider>
                </ItemSlotsProvider>
            </ItemStoreController>
        </GapiProvider>

    
    )

}