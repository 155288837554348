import { useParams } from 'react-router-dom';
import React, {FC, ReactElement, useEffect, useState} from 'react';
import { CogintTokenDocI, CogintTokens, DocsByItemRefIndexName, IsValidToken, ItemDocI } from 'cogint-fauna';
import { AcceptCogintToken } from 'cogint-fauna';
import { useFaunaContext } from 'Models/Fauna';
import { Button } from "cntric-component-library";
import { Intelephant } from 'Views/Components/Logos';
import { useTheme } from 'Views/Theme';
import { InfoBlock } from 'Views/Components/Info';
import { LoadingPage } from 'Controllers/Pages/LoadingPage';
import {to} from "await-to-js";
import { useAlert } from 'react-alert';
import {query as q} from "faunadb";
import { MyIdentity } from 'cogint-fauna';
import { useNavigate } from 'react-router-dom';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { domain } from 'process';

export type AcceptTokenControllerProps = {

}

export const AcceptTokenController : FC<AcceptTokenControllerProps>  = () =>{


    const {primaryColor, secondaryColor} = useTheme();
    const {tokenId} = useParams();
    const {faunaClient} = useFaunaContext();
    const {domainId} = useDomainContext();

    const nav = useNavigate();

    const alert = useAlert();

    const handleClick = async ()=>{
        if(tokenId && faunaClient){
            const [err, result] = await to(faunaClient.query<CogintTokenDocI>(
                AcceptCogintToken(tokenId)
            ));
            if(err) alert.error(err.message);
            if(result){
                console.log(result);
                alert.success(`Token ${tokenId} accepted!`);
                nav(`/app/d/${domainId}/dashboard/${result.ref.id}`)
            }
        }
    };

    const [isValidToken, setValidToken] = useState<boolean|undefined>(undefined);
    useEffect(()=>{
        tokenId && faunaClient && faunaClient.query<boolean>(
            IsValidToken(tokenId)
        ).then((data)=>setValidToken(data));
    }, [faunaClient])


    return (

       isValidToken === undefined ? <LoadingPage/> : <div style={{
           background : primaryColor,
           width : "100vw",
           height : "100vh",
           display : "grid",
           justifyContent : "center",
           justifyItems : "center",
           alignContent : 'center',
           alignItems : "center"
       }}>
          <div style={{
              display : "grid",
              justifyContent : "center",
              justifyItems : "center",
              width : "300px",
              background : secondaryColor,
              borderRadius : "10px",
              padding : "10px",
              height : "auto"
          }}>
                {isValidToken ?  
                <>
                    <div>
                        <Intelephant size={100} color={primaryColor}/>
                    </div>
                    <br/>
                    <div>
                        <InfoBlock type="info" docsRef="token">
                            You've been invited to access an item linked to this token. Please click the button below to accept.
                        </InfoBlock>
                    </div>
                    <br/>
                    <div style={{
                        width : "100%"
                    }}>
                        <Button
                            style={{
                                width : "100%"
                            }} 
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            action={handleClick}>
                            Accept token
                        </Button>
                    </div>
                </> : <>
                    <div>
                        <Intelephant size={100} color={primaryColor}/>
                    </div>
                    <br/>
                    <div>
                        <InfoBlock type="error" docsRef="invalid-token">
                            This token isn't valid. It may not be meant for you, or it may have already been used.
                        </InfoBlock>
                    </div>
                </>}
               
          </div>
       </div>

    )

}