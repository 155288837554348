import React, { createContext, CSSProperties, FC, useContext, useEffect, useReducer } from 'react';
import {
    ArrowsAngleContract, ArrowsAngleExpand, Back, ChatDotsFill,
    ChatSquareText, ChevronDown, CpuFill, Dash,
    DashCircleFill, ExclamationCircleFill, EyeFill, EyeSlashFill, FilePlus, HeartFill, Icon, InfoCircleFill, Journal, List, Newspaper, People,
    PeopleFill, Person, PersonBadgeFill, PersonLinesFill, PersonSquare, Plus,
    PlusCircleFill, Search, StarFill, Stars, TerminalFill, ThreeDots, Trash2Fill, X
} from "react-bootstrap-icons";
import { ThemeColorsI } from "./ThemeColors";
import {
    interpretThemeColors
} from "./ThemeColors";

export interface ThemeProps extends ThemeColorsI {
    font: CSSProperties["font"],
    Item: FC<any>,
    Dropdown: FC<any>,
    IconSet: {
        Account : FC | Icon,
        AccountSignedIn:  FC | Icon,
        Menu:  FC | Icon,
        Add:  FC | Icon,
        New:  FC | Icon,
        Subtract:  FC | Icon,
        Delete:  FC | Icon,
        Expand:  FC | Icon,
        Contract: FC | Icon,
        Grow: FC | Icon,
        Minimize:  FC | Icon,
        Close: FC | Icon,
        Caret:  FC | Icon,
        Report:  FC | Icon,
        Group:  FC | Icon,
        EmptyGroup:  FC | Icon,
        Client: FC | Icon,
        Partner:  FC | Icon,
        Command:  FC | Icon,
        Widget:  FC | Icon,
        Compute:  FC | Icon,
        Help: FC | Icon,
        Important: FC | Icon,
        Chat: FC | Icon,
        Message: FC | Icon,
        Star: FC | Icon,
        Favorite: FC | Icon,
        Compile: FC | Icon,
        View: FC | Icon,
        Publish: FC | Icon,
        Hide: FC | Icon,
        Search: FC | Icon,
        More:  FC | Icon
    },
    paddingMd: React.CSSProperties["height"]
    isLoading: boolean,
    isLoaded: boolean,
    _inProvider: boolean,
    dispatchTheme : (action : {type : string, payload: any})=>void
    setThemeColors : (newThemeColors: ThemeColorsI)=>void,
    toggleMode : (mode : "standard" | "dark")=>void 
}

export const DefaultTheme : ThemeProps = {
    mode : "standard",
    primaryColor : "#3a5f6c",
    secondaryColor : "#f6f8fa",
    tertiaryColor : "#3D3D3D",
    backgroundColor : "#f6f8fa",
    _primaryColor : "#3a5f6c",
    _secondaryColor : "#f6f8fa",
    _tertiaryColor : "#3D3D3D",
    _backgroundColor : "#f6f8fa",
    _darkPrimaryColor : "#3a5f6c",
    _darkSecondaryColor : "#f6f8fa",
    _darkTertiaryColor : "#3a5f6c",
    _darkBackgroundColor : "#0b1214",
    successColor: "#32CD32",
    errorColor: "salmon",
    warningColor: "#ffda03",
    font: `"Helvetica Neue", Helvetica, Arial, sans-serif`,
    Item: (({children})=>{return <div>{children}</div>}) as FC<any>,
    Dropdown: (({children})=>{return <div>{children}</div>}) as FC<any>,
    IconSet: {
        Account : Person,
        AccountSignedIn:  PersonLinesFill,
        Menu:  List,
        Add:  Plus,
        New:  FilePlus,
        Subtract:  Dash,
        Delete:  Trash2Fill,
        Expand:  ArrowsAngleExpand,
        Contract: ArrowsAngleContract,
        Grow: PlusCircleFill,
        Minimize:  DashCircleFill,
        Close: X,
        Caret: ChevronDown,
        Report:  Journal,
        Group:  PeopleFill,
        EmptyGroup:  People,
        Client: PersonSquare,
        Partner:  PersonBadgeFill,
        Command:  TerminalFill,
        Widget:  Stars,
        Compute:  CpuFill,
        Help: InfoCircleFill,
        Important: ExclamationCircleFill,
        Chat: ChatDotsFill,
        Message: ChatSquareText,
        Star: StarFill,
        Favorite: HeartFill,
        Compile: Back,
        View: EyeFill,
        Publish: Newspaper,
        Hide: EyeSlashFill,
        Search: Search,
        More: ThreeDots
    },
    paddingMd: "20px",
    _inProvider: true,
    isLoading: false,
    isLoaded: false,
    dispatchTheme: (action : {type : string, payload: any})=>{},
    setThemeColors: (newThemeColors: ThemeColorsI)=>{},
    toggleMode : ()=>{}
}

export const OutsideProviderTheme : ThemeProps = {
    ...DefaultTheme,
    isLoading: false,
    isLoaded: false,
    _inProvider: false,
    dispatchTheme: (action : {type : string, payload: any})=>{},
    setThemeColors: (newThemeColors: ThemeColorsI)=>{}
}

export const ThemeContext = createContext(OutsideProviderTheme);

export const themeReducer = (state : ThemeProviderProps, action : {
    type : string,
    payload : any
} )=>{
    switch (action.type) {

        default : {
            return {
                ...state,
                ...action.payload
            } as ThemeProps
        }

    }
}

const makeToggleMode = (state : ThemeProps)=>(mode : "standard" | "dark")=>{

    state.dispatchTheme({
        type :"def",
        payload : {
            ...state,
            ...interpretThemeColors(state, {mode : mode}),
            mode : mode
        }
    })

}

//const ThemeContext = createContext();

export type ThemeProviderProps = {
}

export const ThemeProvider : FC<ThemeProviderProps>  = ({children}) =>{

    const [state, dispatch] = useReducer(themeReducer, DefaultTheme);

    useEffect(()=>{

        

        if(!state.isLoading && !state.isLoaded){
            dispatch({
                type: "def",                
                payload: {
                    // ran into an issue loading imports
                    // safest to import this here
                    Item: (({children})=>{return <div>{children}</div>}) as FC<any>,
                    Dropdown: (({children})=>{return <div>{children}</div>}) as FC<any>,
                    isLoading: true,
                    dispatchTheme: dispatch
                }
            })
        } else if(state.isLoading){
            dispatch({
                type: "def",
                payload: {
                    isLoading: false,
                    isLoaded: true,
                    toggleMode : makeToggleMode(state)
                }
            })
        }
    })

    return (
        <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
    )

}

export const useTheme = ()=>{

    const context = useContext(ThemeContext);

    if(!context._inProvider){
        throw new Error("useTheme must be called within a ThemeProvider.");
    }

    return context;

}