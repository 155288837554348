import {
    Explorer
} from "Controllers/Workspaces/Explorer";
import { 
    useItemStore,
    commit,
    remove,
    get, 
    ItemStoreI
} from "cogint-item-providers-react";
import {
    Layout,
    useLayoutContext
} from "Views/Layouts";
import {
    Footer
} from "Views/Components/Panels/Footer";
import {
    Header
} from "Views/Components/Panels/Header";
import {
    SideNav
} from "Controllers/Navs/SideNav";
import React, { FC, useEffect } from 'react';
import {
    CogintItemFaunaMiddleware
} from "cogint-item-fauna-middleware";
import { useFaunaContext } from "Models/Fauna";
import {ItemI} from "cogint-item-fundaments";
import { pushAtIndex, removeFromSlot, useItemSlotsContext } from "Models/ItemSlots";
import { useTheme } from "Views/Theme";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { synthesizeDirs } from "cogint-item-providers-react";
import { CommitContextController } from "Controllers/CommitController";
import Color from "color";
import { useDomainContext } from "Models/Domain/DomainContextProvider";
import { BuiltInWidgets } from "Controllers/BuiltInWidgets";


export type AnalystDashboardProps = {}

const ManageLayout : FC<{}> = ({children})=>{

    const {
        isLoaded,
        displayHeader,
        toggleHeader,
        displayFooter,
        toggleFooter
    } = useLayoutContext();


    useEffect(()=>{
        if(isLoaded && !displayHeader) toggleHeader(true);
        if(isLoaded && !displayFooter) toggleFooter(true);
    })
    
    return (
        <>
            {children}
        </>
    )

}

export const AnalystDashboard : FC<AnalystDashboardProps>  = () =>{

    const {
        selectItems,
        setItems
    } = useItemStore();
    const {faunaClient} = useFaunaContext();
    const middleware = faunaClient ? CogintItemFaunaMiddleware(faunaClient) : undefined;
    const {domainId} = useDomainContext();

    const {
        mainSlotKey,
        mainSlot,
        setItemSlots
    } = useItemSlotsContext();
    const setMainItems = (
        set : (items : ItemStoreI)=>ItemStoreI
    )=>setItemSlots((slots)=>{
        return {
            ...slots,
            mainSlot : set(slots.mainSlot)
        }
    });

    const _commit = async (item : ItemI)=>{
        
        await commit({
            setItems : setItems,
            selectItems : selectItems,
        }, {[item._id] : item}, middleware);
        setMainItems((items)=>{
            const index = Object.keys(mainSlot).indexOf(item._id);
            const _index = index > -1 ? index : Object.keys(mainSlot).length
            return pushAtIndex(
                {[item._id] : item},
                items,
                _index
            )

        })
    };
    const _remove = async (item : ItemI)=>{
        await remove({
            setItems : setItems,
            selectItems : selectItems
        }, {[item._id] : item}, middleware);
        setMainItems((items)=>removeFromSlot({
            [item._id] : item
        }, items))
    }

    
    const onItemClick = (item : ItemI)=>setItemSlots((slots)=>{
        
        nav(`/app/d/${domainId}/dashboard/${item._id}`);
        return {
            ...slots,
            mainSlotKey : item._id,
            mainSlot : {
                ...slots.mainSlot,
                [item._id] : item
            }
        }
    });

    

    const {
        primaryColor,
        backgroundColor,
        paddingMd,
        tertiaryColor
    } = useTheme();
    const nav = useNavigate();

    const storeItems = selectItems();
    const setActiveItemKey = (key : string)=>{
        nav(`/app/d/${domainId}/dashboard/${key}`);
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlot : {
                    ...slots.mainSlot,
                    [key] : {
                        ...storeItems,
                        ...BuiltInWidgets
                    }[key]
                },
                mainSlotKey : key
            }
        })
    };

    const {id} = useParams();
    useEffect(()=>{
        id && setActiveItemKey(id);
    }, [id])


    const [searchString, setSearchString] = useState("");

    return (
        <CommitContextController
        commit={_commit}
        remove={_remove}>
            <Layout>
                <Layout.Header>
                    <Header
                    searchString={searchString}
                    setSearchString={setSearchString}/>
                </Layout.Header>
                <Layout.Content>
                    <ManageLayout>
                        <Explorer
                        setActiveItemKey={setActiveItemKey}
                        activeItemKey={id||mainSlotKey}
                        style={{
                            padding : paddingMd
                        }}
                        mainItemStyle={{
                            color : primaryColor
                        }}
                        items={mainSlot}
                        setItems={setMainItems}
                        commit={_commit}
                        remove={_remove}/>
                    </ManageLayout>
                </Layout.Content>
                <Layout.SideNav>
                    <SideNav
                    searchString={searchString}
                    setSearchString={setSearchString}
                    style={{
                        paddingLeft : paddingMd,
                        paddingRight : paddingMd,
                        borderRight : `1px solid ${primaryColor}`
                    }}
                    onItemClick={onItemClick}
                    items={storeItems}
                    primaryColor={backgroundColor}
                    secondaryColor={primaryColor}
                    commit={_commit}
                    remove={_remove}/>
                </Layout.SideNav>
                <Layout.Footer>
                    <Footer/>
                </Layout.Footer>
            </Layout>
        </CommitContextController>
    )

}