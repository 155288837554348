import React, {FC, ReactElement} from 'react';
import { DomainContextController } from './DomainContextController';
import { DomainGuardController } from './DomainGuardController';

export type DomainControllerProps = {}

export const DomainController : FC<DomainControllerProps>  = ({children}) =>{

    return (

        <DomainContextController>
            <DomainGuardController>
                {children}
            </DomainGuardController>
        </DomainContextController>

    )

}