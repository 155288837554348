import { EditViews } from 'Controllers/Pages/EditViews';
import React, {FC, ReactElement} from 'react';
import {Routes, Route} from "react-router-dom";
import { Dashboard } from "Controllers/Pages/Dashboard";

export type DashboardRouterProps = {}

export const DashboardRouter : FC<DashboardRouterProps>  = () =>{

    return (

        <Routes>
            <Route index element={<Dashboard/>}/>
            <Route path=":id" element={<Dashboard/>}/>
        </Routes>

    )

}