import React, { FC } from 'react';

export type FooterProps = {}

export const Footer : FC<FooterProps>  = ({children})=>{

    return (
        <>
            {children}
        </>
    )

}