import React, {FC, ReactElement, useState} from 'react';
import {ItemI} from "cogint-item-fundaments";
import { Button, GrowOnEvent } from 'cntric-component-library';
import { ActorRole, PrivilegeInfoBox } from 'cogint-react-resource-doc';
import { BuildCogintRole, CheckPrivilege, FalsePrivs } from 'cogint-fauna';
import { ItemStoreI } from 'cogint-item-providers-react';
import { SelectItem } from './SelectItem';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { useReducer } from 'react';
import { PrivilegeInfoI, Items } from 'cogint-fauna';
import { useFaunaContext } from 'Models/Fauna';
import {query as q, values} from "faunadb";
import { useEffect } from 'react';
import { getIcon } from 'cogint-react-item-namespace';
import { ActorRolePrivilegeSet } from 'cogint-react-resource-doc';
import { NewRoleNode } from './NewRoleNode';


export type NewRoleProps = {
    items : ItemStoreI,
    item : ItemI,
    canAdd : boolean,
    setActors : (set : (actors : values.Ref[])=>values.Ref[])=>void,
    style ? : React.CSSProperties
}

export const NewRole : FC<NewRoleProps>  = ({
    item,
    canAdd,
    items,
    setActors,
    style
}) =>{


    return (

        <div style={{
            display : "grid",
            gap : "5%"
        }}>
            
            {Object.keys(items).map((key)=>{
            
                return  <NewRoleNode
                    key={key}
                    item={item}
                    actor={items[key]}
                    setActors={setActors}/> 
            })}
        </div>

    )

}