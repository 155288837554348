import React, {FC, ReactElement} from 'react';
import {AddGoogleDoc} from "cogint-add-gdoc-widget";
import { useTheme } from 'Views/Theme';
import { Item, ItemI } from 'cogint-item-fundaments';
import { generate } from 'shortid';
import { useGapi } from 'hapi-gapi-toolkit';
import {Client, CurrentIdentity, values} from "faunadb";
import { CheckPrivilege, MyIdentity, DocDocI, NewNet } from 'cogint-fauna';
import { CommitMethodI } from 'cogint-react-item-namespace';
import { useItemStore, commit } from 'cogint-item-providers-react';
import { useFaunaContext } from 'Models/Fauna';
import { useItemSlotsContext } from 'Models/ItemSlots';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import {Button} from "cntric-component-library";
import { useState } from 'react';

export const addNet = async (
    client : Client,
    name : string,
    commit : CommitMethodI,
    addToSlot : (item : ItemI)=>void
)=>{

    const doc =  await client.query<DocDocI>(NewNet({
        name : name,
        nodes : {},
        edges : []
    }));

    await commit(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Net"
    }));

    addToSlot(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Net"
    }));


}

export const AddNetItem = Item({
    _id : "add-network",
    name : "widgets/Create Network Canvas",
    itemType : "Widget"
})

export type AddNetControllerProps = {
    item : ItemI
}

export const AddNetController : FC<AddNetControllerProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();

    const storeContext = useItemStore();
    const {faunaClient} = useFaunaContext();
    const _commit = async (item : ItemI)=>{
        await commit(storeContext, {[item._id] : item});
    }

    const {
        setItemSlots,
    } = useItemSlotsContext();

    const addSlots = (item : ItemI)=>{
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlot : {
                    ...slots.mainSlot,
                    [item._id] : item
                }
            }
        })
    }

    const _addNet = (name : string)=>{
        faunaClient && addNet(
            faunaClient,
            name,
            _commit,
            addSlots
        )
    }

    const [name, setName] = useState("");


    return (

       
        <Form style={{
            width : "100%"
        }}>
            <InputGroup>
                <Form.Control placeholder="Enter name" onChange={(e)=>{
                    setName(e.target.value);
                }} type="text" /><Button primaryColor={primaryColor} secondaryColor={secondaryColor} action={async ()=>{
                    _addNet(name);
                }}>
                    Create Network Canvas
                </Button>
            </InputGroup>
        </Form>

    )

}