import Color from 'color';
import React, {FC, ReactElement} from 'react';
import {AlertComponentPropsWithStyle, AlertType} from "react-alert"
import { ErrorAlert } from './ErrorAlert';
import { InfoAlert } from './InfoAlert';
import { SuccessAlert } from './SuccessAlert';

export const AlertTypesToColors  : {
    [key in AlertType] : React.CSSProperties["color"] 
} = {
    success : "#228B22",
    info : "#072A6C",
    error : "salmon"
}

export type AlertTemplateProps = AlertComponentPropsWithStyle & {}

export const AlertTemplate : FC<AlertTemplateProps>  = ({
    options,
    message,
    close
}) =>{

    return (

        <div 
        className={"shadow-sm"}
        style={{
            background : AlertTypesToColors[options.type||"info"],
            borderRadius : "2px",
            color : "white",
            fontSize : "12px",
            width : "200px",
            padding : "10px",
            margin : "10px",
            borderLeft : `4px solid ${Color(AlertTypesToColors[options.type||"info"])
                                    .lighten(.1).hex()}`
        }}>
            {
                options.type === "info" ? <InfoAlert message={message}/> 
                : options.type === "error" ? <ErrorAlert message={message}/>
                : options.type === "success" ? <SuccessAlert message={message}/>
                : <InfoAlert message={message}/>
            }
        </div>

    )

}