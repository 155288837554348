import { useAuth0 } from "@auth0/auth0-react";
import { useArbitraryStoreContext } from "Models/ArbitraryStore";
import { Searchbar } from "cntric-component-library";
import {
    Button
} from "cntric-component-library";
import { useLayoutContext } from "Views/Layouts";
import { AccountToggle } from "Views/Components/Toggles/AccountToggle";
import { BrandToggle } from "Views/Components/Toggles/BrandToggle";
import React, { FC, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import {
    ArrowUpRightSquare, BorderBottom, Toggles
} from "react-bootstrap-icons";
import { useTheme } from "Views/Theme";
import {useNavigate} from "react-router-dom";
import Color from "color";
import {ToggleSwitch} from "Views/Components/Actions/Standard/ToggleSwitch";
import { useItemStore } from "cogint-item-providers-react";
import { filterBySearch } from "../../../../Models/lib/search";
import { TabItem, LineItem } from "cogint-react-item-namespace";
import { ViewSelectButton } from "Controllers/Pages/EditViews";
import { ViewModeSelection } from "Controllers/View/ViewModeSelection";
import { useDomainContext } from "Models/Domain/DomainContextProvider";
import { useDashboardMode } from "Models/Dashboard/DashboardContextProvider";
import { Intelephant } from "Views/Components/Logos";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type HeaderProps = {
    searchString : string,
    setSearchString : (searchString : string)=>void
}

/**
 * @description 
 */
export const Header : FC<HeaderProps> = ({
    searchString,
    setSearchString
}) =>{

    const {
        mode,
        primaryColor,
        secondaryColor,
        paddingMd,
        IconSet
    } = useTheme();
    const {
        Menu
    } = IconSet;

    const {
        displaySideNav,
        toggleSideNav
    } = useLayoutContext();


    const handleMenuToggle = ()=>{
        toggleSideNav(true);
    }

    const nav = useNavigate();

    const handleHomeToggle = ()=>{
        nav("/app");
    }

    const {
        logout
    } = useAuth0();

    const handleSearch = (searchString : string)=>setSearchString(searchString)

    const {mode : viewMode, setMode} = useDashboardMode();

    const {
        toggleMode
    } = useTheme();

    const {
        selectItems
    } = useItemStore();

    const filteredItems = filterBySearch(
        selectItems(),
        searchString,
        (item)=>`${item.name}:${item.itemType}`
    );
    
   
    return (
        
        <Navbar style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: paddingMd,
            paddingRight: paddingMd,
            height: "100%",
            display: 'grid',
            gridTemplateColumns: "4fr 6fr 4fr",
            alignItems: "center",
            alignContent: "center",
            borderBottom : `1px solid ${primaryColor}`
            }}>
            {!displaySideNav&& 
                <>
                    <Nav.Item style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr"
                    }}>
                         <div style={{
                            display: viewMode !== "Stakeholder" ? "grid" : "flex",
                            gridTemplateColumns: `1fr 10fr`,
                            alignItems: "center",
                            alignContent: "center"
                        }}>
                            <div style={{
                                justifySelf: "left",
                                cursor: "pointer"
                            }}>
                                {viewMode !== "Stakeholder" && <Menu onClick={handleMenuToggle} size={"24px"} color={primaryColor}/>}
                            </div>
                            <div 
                            onClick={handleHomeToggle}
                            style={{
                                alignContent: "left",
                                textAlign: "left",
                                cursor : "pointer",
                                padding: viewMode !== "Stakeholder" ? paddingMd : "0px"
                            }}>
                                <Intelephant color={primaryColor} label/>     
                            </div>
                        </div>
                        <ToggleSwitch on={mode === "dark"} onChange={(isOn : boolean)=>{
                            toggleMode(
                                isOn ? "dark" : "standard"
                            )
                        }}>
                            <ToggleSwitch.OnLabel>
                                <span style={{
                                    color : primaryColor,
                                    fontSize: "12px"
                                }}>&ensp;Dark theme</span>
                            </ToggleSwitch.OnLabel>
                            <ToggleSwitch.OffLabel>
                                <span style={{
                                    color : primaryColor,
                                    fontSize: "12px"
                                }}>&ensp;Light theme</span>
                            </ToggleSwitch.OffLabel>
                        </ToggleSwitch>
                    </Nav.Item>
                    <Nav.Item style={{
                        display: "flex",
                        justifyItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        <div style={{
                            width: "60%",
                            height: "100%",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            position : "relative"
                        }}>
                            <Searchbar 
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            tertiaryColor={primaryColor}
                            size="sm" onChange={handleSearch} initString={searchString}/>
                             {searchString.length ?
                                <div style={{
                                    position : "absolute",
                                    border : `1px solid ${primaryColor}`,
                                    borderRadius : "10px",
                                    padding : "10px",
                                    background : secondaryColor,
                                    color : primaryColor,
                                    width : "100%",
                                    top : "100%",
                                    textAlign : "left"
                                }}>
                                    <h6>{Object.keys(filteredItems).length} results for "{searchString}"</h6>
                                    <hr/>
                                    {Object.keys(filteredItems).slice(0, 5).map((key)=>{
                                        return <LineItem
                                            style={{
                                                width : "100%",
                                                color : primaryColor
                                            }}
                                            key={key}
                                            item={filteredItems[key]}
                                        />
                                    })}
                                </div> : <></>}
                        </div>
                    </Nav.Item>
                </>
            }
            {displaySideNav&&
                <>
                    <div>
                    </div>
                    <div>

                    </div>
                </>
            }
            <Nav.Item style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
            }}>
                <ViewModeSelection/>
                &ensp;
                <Button 
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                size="sm" onClick={()=>{
                    logout({
                        returnTo: window.location.protocol + '//' + window.location.host
                    })
                }}>Logout</Button>
            </Nav.Item>
        </Navbar>

    )

}