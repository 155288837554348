/**
 * Filters store by a search string.
 * @param items 
 * @param search 
 * @param combinator 
 * @returns 
 */
 export const filterBySearch = <T extends {[key : string] : any}>(
    items : T,
    search : string,
    combinator : (item : T[string])=>string
) : T =>Object.keys(items).reduce((agg, key)=>{
    const comb = combinator(items[key]).toLowerCase();
    const _search = search.toLowerCase()
    const matches = _search.includes(comb) || comb.includes(search) || _search.trim() === "";
    return {
        ...agg,
        ...matches ? {[key] : items[key]} : {}
    }
}, {} as T)