import { DashboardContextProvider } from 'Models/Dashboard/DashboardContextProvider';
import React, {FC, ReactElement} from 'react';
import { DashboardController } from './DashboardController';

export type DashboardProps = {}

export const Dashboard : FC<DashboardProps>  = () =>{

    return (

        <DashboardContextProvider>
            <DashboardController/>
        </DashboardContextProvider>

    )

}