import React, {useReducer, createContext, FC, useContext} from "react";
import {ItemStoreI} from "cogint-item-providers-react";
import {ItemI} from "cogint-item-fundaments";
interface ItemSlotsContextI {
    mainSlot : ItemStoreI
    mainSlotKey ? : string,
    setItemSlots : React.Dispatch<(items: ItemSlotsContextI) => ItemSlotsContextI>
}
const ItemSlotsContext : React.Context<ItemSlotsContextI> = createContext({
    mainSlot : {},
    mainSlotItem : undefined,
    setItemSlots : ()=>{}
} as ItemSlotsContextI);
const itemsReducer = (state : ItemSlotsContextI, set : (items : ItemSlotsContextI)=>ItemSlotsContextI)=>set(state);
/**
 * Provides access to a ItemSlots client throughout the application.
 * @param param0 
 * @returns 
 */
export const ItemSlotsProvider : FC  = ({children})=>{
    
    const [state, setItemSlots] = useReducer(itemsReducer, {
        mainSlot : {},
        setItemSlots: ()=>{}
    });

    return <ItemSlotsContext.Provider value={{
        ...state,
        setItemSlots : setItemSlots
    }}>
        {children}
    </ItemSlotsContext.Provider>

}
/**
 * Hook for using ItemSlots context.
 * @returns 
 */
export const useItemSlotsContext = () : ItemSlotsContextI=>{

    const context = useContext(ItemSlotsContext);
    if(!context){
        throw new Error("useItemSlotsContext must be called within a ItemSlotsProvider.");
    }
    return context;

}