import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import {Containers} from "cogint-fauna";
import { useFaunaContext } from 'Models/Fauna';
import {query as q } from 'faunadb';
import { ItemStoreI, useItemStore } from 'cogint-item-providers-react';
import { ContainerController } from './ContainerController';
import { ContainerWrapper } from './ContainerWrapper';
import { useTheme } from 'Views/Theme';
import { useState } from 'react';
import { Button } from 'cntric-component-library';
import { ArrowLeft } from 'react-bootstrap-icons';
import {motion} from "framer-motion";
import { ReceiptlikeDoc } from 'cogint-fauna/dist/cjs/CogintFaunaClient/Objects/Primitives/Receipt/Receipt';


export type ContainersControllerProps = {
    data : ReceiptlikeDoc[]
}

export const ContainersController : FC<ContainersControllerProps>  = ({
    data 
}) =>{

    const receiptItemIds = data.map((receipt)=>receipt.data.item.id);

    const {selectItems} = useItemStore();
    const {tertiaryColor} = useTheme();

    const containers = selectItems((items)=>{
        return Object.keys(items).reduce((agg, key)=>{
            return {
                ...agg,
                ...items[key].itemType === "Container" ? {
                    [key] : items[key]
                } : {}
            }
        }, {});
    });

    const reportsAndNetworks = selectItems((items)=>{
        return Object.keys(items).reduce((agg, key)=>{
            return {
                ...agg,
                ...(items[key].itemType === "Doc" || items[key].itemType === "Net") 
                && receiptItemIds.includes(key) ? {
                    [key] : items[key]
                } : {}
            }
        }, {});
    });

    const [singleContainerView, setSingleContainerView] = useState<{
        toggled : boolean,
        value : string | undefined
    }>({
        toggled : false,
        value : undefined
    });

    



    return  singleContainerView.toggled ? (
            <div style={{
                textAlign : "left",
                display : "flex",
                gridTemplateColumns : "auto auto"
            }}>
                <motion.div 
                onClick={()=>{
                    setSingleContainerView({
                        toggled : false,
                        value : undefined
                    })
                }}
                transition={{duration : .2}}
                animate={{
                    width : ["0px", "50px"],
                    opacity : [0, 1, 1]
                }}>
                    <Button>
                        <ArrowLeft/>
                    </Button>
                </motion.div>
                <div style={{
                    flexGrow : "1"
                }}>
                    <h3>{singleContainerView.value ? containers[singleContainerView.value].name : "Untagged data"}</h3>
                    <hr/>
                    <ContainerController 
                    storeItems={reportsAndNetworks}
                    containerItem={singleContainerView.value ? containers[singleContainerView.value] : undefined}/>
                </div>
            </div>    
        ) : (

        <div>
            {Object.keys(containers).map((containerId)=>{
                return <ContainerController 
                onClick={()=>{
                    setSingleContainerView({
                        toggled : true,
                        value : containerId
                    })
                }}
                storeItems={reportsAndNetworks}
                containerItem={containers[containerId]}/>
            })}
            <ContainerController
            onClick={()=>{
                setSingleContainerView({
                    toggled : true,
                    value : undefined
                });
            }}
            storeItems={reportsAndNetworks}/>
        </div>

    ) 

}