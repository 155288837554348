import { ItemI } from 'cogint-item-fundaments';
import React, {FC, ReactElement, useMemo} from 'react';
import { CogintRolesByItem, Items, GetRelevantActorRoles, GetUserActors, MyIdentity, CheckPrivilege } from 'cogint-fauna';
import { useState } from 'react';
import { values, query as q } from 'faunadb';
import { useEffect } from 'react';
import { useFaunaContext } from 'Models/Fauna';
import { useItemStore } from 'cogint-item-providers-react';
import { RoleConfigActorNode } from './RoleConfigActorNode';
import { NewRole } from './NewRole';
import { Searchbar } from 'cntric-component-library';
import { useTheme } from 'Views/Theme';
import { RoleConfigInviteByEmail } from './RoleConfigInviteByEmail';

export const isActor = (item : ItemI)=>{
    return item.itemType === "Container" || item.itemType === "User" || item.itemType === "Domain" || item.itemType === "Group";
}

export type RoleControllerProps = {
    item : ItemI
}

export const RoleController : FC<RoleControllerProps>  = ({
    item
}) =>{

    const [searchString, setSearchString] = useState("");
    const {
        primaryColor,
        secondaryColor
    } = useTheme();

    const {
        faunaClient
    } = useFaunaContext();

    const {
        selectItems
    } = useItemStore();
    const items = selectItems();

    // get all actor refs with roles attached to the item
    const [actors, setActors] = useState<values.Ref[]|undefined>(undefined);
    useEffect(()=>{
        if(faunaClient){
            faunaClient.query<values.Ref[]>(
                    q.Map(
                       q.Select("data", q.Paginate(
                           q.Match(
                               CogintRolesByItem(),
                               q.Ref(Items(), item._id) as values.Ref
                           )
                       )),
                        q.Lambda(
                            'el',
                            q.Select(["data", "actor"], q.Get(q.Var('el')))
                        )
                    ),
            ).then((data)=>{
                setActors(data);
            })
        }
    }, [item, faunaClient]);

    const [canAdd, setAdd] = useState(false);
    useEffect(()=>{
        if(faunaClient){
            faunaClient.query<boolean>(
                CheckPrivilege(q.Ref(
                    Items(),
                    item._id
                ) as values.Ref, "write")
            ).then((data)=>{
                setAdd(data);
            })
        }
    }, [item, faunaClient]);

    if(!actors) return <></>

    const actorRefIds = actors.map((actor)=>actor.id);
    const unaccountedActors = Object.keys(items).filter((key)=>(items[key].itemType === "Domain" || items[key].itemType === "User" || items[key].itemType === "Group")).reduce((agg, key)=>{
        return {
            ...agg,
            ...(!actorRefIds.includes(key) && items[key] && isActor(items[key])) ? {
                [key] : items[key]
            } : {}
        }
    }, {})

    const actorRoles = actors.map((ref)=>{

        return <RoleConfigActorNode
        canEdit={canAdd}
        key={ref.id}
        actorRef={ref}
        itemId={item._id}/>

    })

    const _setActors = (set : (actors : values.Ref[])=>values.Ref[])=>{
        setActors(set(actors));
    }


    return (

        <div style={{
            display : "grid",
            gap : "10px",
            gridTemplateColumns : "1fr",
            zIndex : 5000,
            position : "relative",
            height : "200px",
            overflowY: 'scroll',
            fontSize : "13px"
        }}>
            <Searchbar
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            onChange={setSearchString}/>
            <h6>Email: </h6>
            <RoleConfigInviteByEmail 
            item={item}
            size="sm" style={{
                textAlign : "left",
                width : "100%"
            }} text={searchString}/>
            <br/>
            <div>
            {Object.keys(unaccountedActors).length > 0 && <><h6>Unassigned actors: </h6>
            <NewRole
            canAdd={true}
            item={item}
            items={unaccountedActors}
            setActors={_setActors}/></>}
            </div>
            <br/>
            <div>
                {actorRoles.length > 0 && <>
                    <h6>Existing roles: </h6>
                    <div style={{
                        display : "grid",
                        gap : "5%",
                        zIndex : 10000000
                    }}>
                        {actorRoles}
                    </div>
                </>}
            </div>
        </div>

    )

}