import React, {FC, ReactElement} from 'react';
import {AddGoogleDoc} from "cogint-add-gdoc-widget";
import { useTheme } from 'Views/Theme';
import { Item, ItemI } from 'cogint-item-fundaments';
import { generate } from 'shortid';
import { useGapi } from 'hapi-gapi-toolkit';
import {Client, CurrentIdentity, values} from "faunadb";
import { CheckPrivilege, MyIdentity, DocDocI, NewNet } from 'cogint-fauna';
import { CommitMethodI } from 'cogint-react-item-namespace';
import { useFaunaContext } from 'Models/Fauna';
import { useItemSlotsContext } from 'Models/ItemSlots';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import {Button} from "cntric-component-library";
import { useState } from 'react';
import { Stars } from 'react-bootstrap-icons';
import { useSlots } from 'Models/Slots';
import { useNavigate } from 'react-router-dom';
import { useItemStore } from 'cogint-item-providers-react';
import { AddGoogleDocItem } from '../AddDocController';
import { AddNetItem } from '../AddNetController';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { BuiltInWidgets } from '..';



export const DocOrNetItem = Item({
    _id : "home",
    name : "widgets/Home",
    itemType : "Widget"
})

export type DocOrNetControllerProps = {
    item : ItemI
}

export const DocOrNetController : FC<DocOrNetControllerProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();
    const {setItemSlots, mainSlotKey, mainSlot} = useItemSlotsContext();
    const nav = useNavigate();
    const {selectItems} = useItemStore();
    const {domainId} = useDomainContext();

    console.log(mainSlot, mainSlotKey);

    const navTo = (key : string)=>{
        console.log(key);
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlot : {
                    ...slots.mainSlot,
                    [key] : BuiltInWidgets[key]
                },
                mainSlotKey : key
            }
        });
        nav(`/app/d/${domainId}/dashboard/${key}`);
    }

    const handlePickDoc = ()=>{
        navTo(AddGoogleDocItem._id);
    }

    const handlePickCanvas = ()=>{
        navTo(AddNetItem._id);
    }
   

    return (

       
        <div style={{
            display : "grid",
            gridTemplateColumns : "1fr 1fr 1fr",
            gap : '10px',
            gridTemplateRows : "200px"
        }}>
            <Button 
            onClick={handlePickDoc}
            primaryColor={primaryColor} secondaryColor={secondaryColor}>
                <Stars/> Pick Google Doc
            </Button>
            <Button 
            onClick={handlePickCanvas}
            primaryColor={primaryColor} secondaryColor={secondaryColor}>
                <Stars/> Create Network Canvas
            </Button>
        </div>

    )

}