import Color from "color";
import React, { FC } from 'react';
import {
    useTheme
} from "Views/Theme";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type IntelephantProps = {
    style ? : React.CSSProperties,
    color?: React.CSSProperties["color"],
    label?: boolean,
    size? : React.CSSProperties["height"]
}

/**
 * @description 
 */
export const Intelephant : FC<IntelephantProps>  = ({color, label=false, size="50px", style}) =>{


    const lightenedUsed= Color(color).lighten(.5).mix(Color("grey")).hex();


    return (

  
        <div style={{
            display: "grid",
            height: size,
            width : size,
            alignItems: "center",
            alignContent: "center",
            justifyItems : "center",
            justifyContent : "center"
        }}>
                <svg 
                    style={{
                        height: size,
                        ...style
                    }}
                    id="eJfprZcDKPT1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 480"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision">
                    <g id="eJfprZcDKPT15"
                    transform="matrix(0.791851 0 0 0.791856 66.607684 49.954563)">
                        <ellipse id="eJfprZcDKPT16"
                                rx="250.071516"
                                ry="250.071516"
                                transform="matrix(1.146838 0 0 1.146838 319.999998 240.000001)"
                                fill={color}
                                stroke="none"
                                strokeWidth="0" />
                        <path id="eJfprZcDKPT17"
                            d="M315.50829,427.24406L315.3088,426.71758L293.41727,426.71758L293.41727,368.94136L246.03762,243.89681L246.48166,243.89351L241.08306,229.31131L193.45268,273.54719L83.27269,212.18112L107.5878,88.43056L232.79535,73.31458L249.3292,108.96001L250.04414,107.02889L315.50829,76.69038L380.97244,107.02889L381.21626,107.68747L402.26206,69.90069L526.01262,94.2158L541.1286,219.42335L426.72023,272.49076L388.98858,231.86371L384.91584,242.86462L384.97896,242.86415L381.3644,252.45744L380.97244,253.51616L380.96402,253.52006L337.59931,368.61296L337.59931,426.71757L315.70665,426.71757L315.50829,427.24406Z"
                            transform="matrix(1.117404 0 0 1.117404 -28.854255 -4.162893)"
                            fill={lightenedUsed}
                            stroke="none"
                            strokeWidth="0" />
                    </g>
                </svg>
        </div>


    )

}