import { MyIdentityContextProvider } from 'Models/MyIdentity';
import React, {FC, ReactElement} from 'react';
import {MyIdentityGuardController} from "./MyIdentityControllerGuard";

export type MyIdentityControllerProps = {}

export const MyIdentityController : FC<MyIdentityControllerProps>  = ({children}) =>{

    return (

        <MyIdentityContextProvider>
            <MyIdentityGuardController>
                {children}
            </MyIdentityGuardController>
        </MyIdentityContextProvider>

    )

}