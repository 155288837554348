import { ItemStoreI } from "cogint-item-providers-react";
import { AddGoogleDocItem } from "./AddDocController";
import {AddNetItem} from "./AddNetController"
import { CreateContainerItem } from "./CreateContainer/CreateContainer";
import { CreateDomainItem } from "./CreateDomain";
import { CreateGroupItem } from "./CreateGroup/CreateGroup";
import { DocOrNetController, DocOrNetItem } from "./DocOrNetwork/DocOrNetwork";

export * from "./AddDocController";
export * from "./AddNetController";
export const BuiltInWidgets : ItemStoreI = [AddGoogleDocItem, AddNetItem, CreateContainerItem, DocOrNetItem].reduce((agg,  item)=>{
    
    return {
        ...agg,
        [item._id] : item
    }
}, {})

export const LeadBuiltInWidgets : ItemStoreI = [AddGoogleDocItem, AddNetItem, CreateDomainItem, CreateGroupItem, CreateContainerItem, DocOrNetItem].reduce((agg, item)=>{
    return {
        ...agg,
        [item._id] : item
    }
}, {})