import { CogintViewlike, ReceiptsByOnAndUser } from 'cogint-fauna';
import { useFaunaContext } from 'Models/Fauna';
import React, {FC, ReactElement} from 'react';
import { createContext } from 'react';
import { useEffect } from 'react';
import {useParams} from "react-router-dom";
import {query as q, values} from "faunadb";
import { useReducer } from 'react';
import { useContext } from 'react';
import {HaveAgreedToS} from "cogint-fauna"
import { ReceiptsByActAndOnAndUser } from 'cogint-fauna';
import { AgreeActionName } from 'cogint-fauna';
import { ToSName } from 'cogint-fauna';
import { MyIdentity } from 'cogint-fauna';

export type ToSContext = {
    /** whether the user has agreed to the ToS. */
    agreed : boolean
    /** refreshes the ToS context. */
    refresh : ()=>void,
    /** number of refreshes. */
    refreshCount : number,
    /** whether the ToS context has been loaded. */
    loaded : boolean,
}

/**
 * By default the tos name should be root
 * and the views should be empty.
 */
export const DefaultToSContext : ToSContext = {
    agreed : false,
    refresh : ()=>{},
    refreshCount : 0,
    loaded : false
}; 

export const ToSCtx = createContext(DefaultToSContext);

/**
 * Reduces the state of a tos with a setter function.
 * @param state is the state of the tos before the reductino.
 * @param set is a function that sets the state of the tos.
 * @returns the new state of the tos.
 */
export const tosReducer = (state : ToSContext, set : (state : ToSContext)=>ToSContext)=>set(state);

export type ToSContextProps = {
    handleError ? : (err : Error)=>void
}

/**
 * Provides access to ToSContext. Handles view fetching for ToSContext.
 * @param param0 
 * @returns 
 */
export const ToSContextProvider : FC<ToSContextProps>  = ({children, handleError}) =>{

    const [state, dispatch] = useReducer(
        tosReducer,
        DefaultToSContext
    );
    const [tick, forceUpdate] = useReducer(x=>x+1, 0);

    const {faunaClient} = useFaunaContext();
    useEffect(()=>{

       faunaClient && faunaClient.query<boolean>(HaveAgreedToS()).then((agreed)=>{
            dispatch((state)=>{
                return {
                    ...state,
                    agreed : agreed,
                    loaded : true
                }
            })
        }).catch((err)=>{
            handleError && handleError(err);
        })

    }, [faunaClient, tick]);

    const refresh = ()=>{
        forceUpdate();
        dispatch((state)=>({
            ...state,
            loaded : false
        }))
    }

    return (

        <ToSCtx.Provider value={{
            ...state,
            refresh : refresh,
            refreshCount : tick
        }}>
            {children}
        </ToSCtx.Provider>

    )

}

/**
 * Gives read-only access to tos context.
 * @returns the current tos context.
 */
export const useToSContext = () : Readonly<ToSContext> =>{
    const context = useContext(ToSCtx);
    if(!context) throw new Error("useToSContext must be called from within a context provider.");
    return context;
}