import React, {FC, ReactElement, Children} from 'react';
import { useState } from 'react';
import { ExclamationCircleFill, InfoCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ColorsByName } from 'Views/Theme/ThemeColors';

export type InfoBlockProps = {
    type : "info" | "error" | "warning"
    docsRef : string,
    style ? : React.CSSProperties
}

export const InfoBlock : FC<InfoBlockProps>  = ({
    type,
    children,
    docsRef,
    style,
}) =>{
    
    const [hover, setHover] = useState(false);
    const handleMouseOver = ()=>{
        setHover(true);
    }
    const handleMouseOut = ()=>{
        setHover(false);
    }

    const nav = useNavigate();

    const handleLearnMore = ()=>{
        nav(`/app/info/${docsRef}`)
    }

    return (

        <div 
        onClick={handleLearnMore}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
            display : "grid",
            gridTemplateColumns : "1fr 5fr",
            border : `1px solid ${ColorsByName[type]}`,
            borderRadius : "5px",
            backdropFilter : hover ? "invert(5%)" : "none",
            cursor : hover ? "pointer" : "auto",
            alignContent : 'center',
            alignItems : "center",
            justifyContent : 'center',
            justifyItems : 'center',
            padding : "10px",
            color : ColorsByName[type],
            ...style
        }}>
            {
                type === "info" ?
                <InfoCircleFill size={20}/>
                : type === "error" ? 
                <XCircleFill size={20}/>
                : <ExclamationCircleFill size={20}/>
            }
            <div
            onClick={handleLearnMore}
            style={{
                textAlign : "left",
                color : ColorsByName["darkGray"]
            }}>
                {children}&nbsp;<a href={`/app/info/${docsRef}`}>
                    Learn more.
                </a>
            </div>
        </div>

    )

}