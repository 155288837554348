import React, {FC, ReactElement} from 'react';
import {
    XAxis,
    YAxis,
    Line,
    LineChart,
    Tooltip,
    CartesianGrid
} from "recharts";
import moment from "moment";
import { useTheme } from 'Views/Theme';
import Color from 'color';
import { ReceiptlikeDoc } from 'cogint-fauna/dist/cjs/CogintFaunaClient/Objects/Primitives/Receipt/Receipt';
import { TimeFilterMode } from 'Controllers/Workspaces/Stakeholder';
import { DateMap } from 'Controllers/Workspaces/Stakeholder';

export const dateKey = (date : Date)=>{
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
}

/**
 * Makes dates corresponding to a time filter mode.
 * @param mode 
 */
export const makeDates = (mode : TimeFilterMode) : {[key : string] : number}=>{
    if(mode !== "All"){
        const now = new Date();
        const iterDate = DateMap[mode]();
        let map : {[key : string] : number} = {};
        while(iterDate <= now){
            map[dateKey(iterDate)] = 0;
            iterDate.setDate(iterDate.getDate() + 1);
        }
        return map;
    } else return {};
}

export type IntelligenceDataGraphProps = {
    data : ReceiptlikeDoc[],
    mode : TimeFilterMode
}

export const IntelligenceDataGraph : FC<IntelligenceDataGraphProps>  = ({
    data,
    mode
}) =>{

    console.log(mode);

    const _data = data.reduce((agg, receipt)=>{

        const date = new Date(receipt.ts/1000);
        const key = dateKey(date);
        
        return {
            ...agg,
            [key] : (agg[key] || 0) + 1
        }
    }, makeDates(mode));
    
    

    const __data = Object.keys(_data)
    .map((key)=>{
        return {value : _data[key], time : new Date(key).getTime()}
    });

    

    const {tertiaryColor} = useTheme();
    const lightened = Color(tertiaryColor).lighten(1.5).hex();

    return (

        <LineChart 
        margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
        width={600} height={280} data={__data}>
            <CartesianGrid vertical={false} stroke={lightened} strokeDasharray="5 5"/>
            <XAxis
                dy={20}
                tickSize={2}
                tickCount={5}
                dataKey="time" 
                tickFormatter = {(date) => {
                    
                    return new Date(date).toLocaleDateString()
                }}
                type='number'
                domain={['auto', 'auto']}/>
            <YAxis 
            width={0}
            domain={['auto', 'auto']}
            tickLine={false}
            axisLine={false}
            tickCount={4}
            dataKey="value" />
            <Line 
            type="monotone" dataKey="value" stroke={tertiaryColor} />
            <Tooltip />
        </LineChart>
    )

}