import { ItemI } from 'cogint-item-fundaments';
import React, {FC, ReactElement} from 'react';
import { getIcon } from 'cogint-react-item-namespace';
import { ActorRole } from 'cogint-react-resource-doc';

export type ActorNodeProps = {
    actor : ItemI
}

export const ActorNode : FC<ActorNodeProps>  = ({
    actor
}) =>{

    const ActorIcon = getIcon(actor);

    return (

        <div style={{
            display : "flex",
            alignContent : "center",
            alignItems : "center"
        }}><ActorIcon size={12}/>&emsp;{actor.name.length > 15 ? actor.name.slice(0, 15)+"..." : actor.name}</div>

    )

}