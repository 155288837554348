import React, {FC, ReactElement, useEffect, useRef, useState} from 'react';
import {IntelligenceDataPieChart} from "./Data/Charts/IntelligenceDataPieChart";
import { IntelligenceDataGraph } from './Data/Charts/IntelligenceDataGraph';
import { IntelligencePieAndChart } from 'Controllers/Workspaces/Stakeholder/Data/Charts/IntelligencePieAndChart/IntelligencePieAndChart';
import { useFaunaContext } from 'Models/Fauna';
import { ReceiptlikeDoc } from 'cogint-fauna/dist/cjs/CogintFaunaClient/Objects/Primitives/Receipt/Receipt';
import { GetMyReadReceipts, GetMySentReceipts } from 'cogint-fauna';
import {query as q} from "faunadb";
import { ContainersController } from './Data/ByContainer/ContainersController';
import { InfoBlock } from 'Views/Components/Info';

export const FilterModes = ["1W", "1M", "1Y", "All"] as const;
export type TimeFilterMode = typeof FilterModes[number];
export const weekAgo = (date ? :Date)=>{
    const _date = date ? new Date(date) : new Date();
    _date.setTime(_date.getTime() - (60*60*24*7*1000));
    return _date;
  }
  
  export const yearAgo = (date ? : Date)=>{
    const _date = date ? new Date(date) : new Date();
    _date.setFullYear(_date.getFullYear() - 1);
    return _date;
  }
  
  export const monthAgo = (date ? : Date)=>{
    const _date = date ? new Date(date) : new Date();
    _date.setMonth(_date.getMonth() - 1);
    return _date;
  }
  
  export const allTime = ()=>{
    return new Date(1970, 0, 1, 0, 0, 0, 0);
  }
  
  export const DateMap : {[key in TimeFilterMode] : ()=>Date} = {
    "1W" : weekAgo,
    "1M" : monthAgo,
    "1Y" : yearAgo,
    "All" : allTime
  }

export type StakeholderHomeProps = {}

export const StakeholderHome : FC<StakeholderHomeProps>  = () =>{

    const {faunaClient} = useFaunaContext();

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState<ReceiptlikeDoc[]>([]);
    useEffect(()=>{
      if(isLoading){
        faunaClient && faunaClient.query<ReceiptlikeDoc[]>(GetMySentReceipts())
        .then((data)=>{
          
          setData(data);
          setLoading(false);
        }); 
      }
    }, [isLoading]);

    const [mode, setMode] = useState<TimeFilterMode>("1W");

    const [filteredData, setFilteredData] = useState(data);
    const lastFilter = useRef<(data : ReceiptlikeDoc[])=>ReceiptlikeDoc[]>((data)=>data);
    const handleFilterData = (providedFilter : (data : ReceiptlikeDoc[])=>ReceiptlikeDoc[])=>{
        setFilteredData(providedFilter(data));
        lastFilter.current = providedFilter;
    };
    useEffect(()=>{
      
      lastFilter.current ? setFilteredData(lastFilter.current(data)) : 
      setFilteredData(data);
    }, [data]);

  

    return (

        <div style={{
            display : "grid",
            gridTemplateRows : "1fr 1fr",
            padding : "2%",
            gap : "10px",
            height : "100%"
        }}>
            
            <IntelligencePieAndChart
            data={data} 
            mode={mode}
            setMode={setMode}
            filteredData={filteredData}
            filterData={handleFilterData}/>
            {!isLoading && <>{filteredData.length > 0 ? <ContainersController data={filteredData}/> : <div> 
              <InfoBlock docsRef="delivered-reports" type={"info"}>No delivered reports match this criteria.</InfoBlock>
            </div>}</>}        
          </div>

    )

}