import { Intelephant } from 'Views/Components/Logos';
import { getFileText } from 'Models/lib';
import React, {FC, ReactElement} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTheme } from 'Views/Theme';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Skeleton from "react-loading-skeleton";
import { TermsOfUseContent } from 'Controllers/Workspaces';
import { useFaunaContext } from 'Models/Fauna';
import { AgreeToS } from 'cogint-fauna';
import { useToSContext } from 'Models/ToS';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {to} from "await-to-js";

export const TERMSOFUSE = "TERMSOFUSE.md";

export type AgreeTermsOfUseProps = {}

export const AgreeTermsOfUse : FC<AgreeTermsOfUseProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();
    const {faunaClient} = useFaunaContext();
    const alert = useAlert();
    const {refresh} = useToSContext();
    const { search } = useLocation();
    const returnTo = new URLSearchParams(search).get("returnTo");
    const nav = useNavigate();

    const handleAgreeToS = async ()=>{
        
        if(faunaClient){
            const [err, result] = await to(faunaClient.query(AgreeToS()));
            
            if(result){
                refresh();
                returnTo ? nav(decodeURI(returnTo)) : nav("/app"); 
            } else {
                err && alert.error(err.message);
            }
        }
    }

    return (

        <div style={{
            background : primaryColor,
            color : secondaryColor,
            width : "100vw",
            minHeight : "100vh",
            display : "grid",
            gridTemplateColumns : "1fr",
            justifyContent : "center",
            justifyItems : "center"
        }}>
           <TermsOfUseContent agreeable={true} onAgree={handleAgreeToS}/>
        </div>

    )

}