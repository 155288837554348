import React, {
    createContext, createRef, FC, RefObject, useContext,
    useEffect, useReducer
} from 'react';


export interface LayoutContextProps {
    isLoading: boolean,
    isLoaded: boolean,
    displayHeader: boolean,
    toggleHeader: (to : boolean)=>void,
    HeaderRef: RefObject<HTMLElement>,
    displayContent: boolean,
    toggleContent: (to : boolean)=>void,
    displaySideNav: boolean,
    toggleSideNav: (to : boolean)=>void,
    displayFooter: boolean,
    toggleFooter: (to : boolean)=>void,
    dispatch: (action : {
        type : string,
        payload : any
    })=>void
    _inProvider: boolean,
}


export const LayoutDefaultContext : LayoutContextProps = {
    isLoading: false,
    isLoaded: false,
    displayHeader: false,
    toggleHeader: (to : boolean)=>{},
    HeaderRef: createRef(),
    displayContent: true, // By default, only the content is displayed.
    toggleContent: (to : boolean)=>{},
    displaySideNav: false,
    toggleSideNav: (to : boolean)=>{},
    displayFooter: false,
    toggleFooter: (to : boolean)=>{},
    dispatch: (action : {
        type : string,
        payload : any
    })=>{},
    _inProvider: true,
}

export const LayoutOutsideProviderContext : LayoutContextProps = {
    ...LayoutDefaultContext,
    _inProvider: false,
}

export const LayoutContext = createContext(LayoutOutsideProviderContext);

/**
* @description The used to modify the state of the LayoutContext
*/
export const LayoutReducer = (state : LayoutContextProps, action : {
    type : string,
    payload : any
} )=>{
    switch (action.type) {

        default : {

            return {
                ...state,
                ...action.payload
            } as LayoutContextProps
        }

    }
}


export type LayoutProviderProps = {
    
}

/**
 * @description Makes toggle member functions.
 */
const makeToggle = (context: LayoutContextProps, which : string) => (to : boolean)=>{

    context.dispatch({
        type : "def",
        payload: {
            ...context,
            [`display${which}`] : to
        }
    })
}

/**
 * @description Makes member functions for LayoutContext.
 */
const makeMemberFunctions = (context: LayoutContextProps)=>{
    return {
        toggleHeader: makeToggle(context, "Header"),
        toggleContent: makeToggle(context, "Content"),
        toggleSideNav: makeToggle(context, "SideNav"),
        toggleFooter: makeToggle(context, "Footer")
    }
}

export const LayoutProvider : FC<LayoutProviderProps>  = ({children}) =>{

    const [state, dispatch] = useReducer(LayoutReducer, LayoutDefaultContext);

    useEffect(()=>{
        if(!state.isLoading && !state.isLoaded){ // initialization phase
            dispatch({
                type : "def",
                payload : {
                    ...state,
                    isLoading: true,
                    dispatch: dispatch
                }
            })
        } else if(state.isLoading){ // loading phase

            dispatch({
                type : "def",
                payload : {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    ...makeMemberFunctions(state)   
                } as LayoutContextProps
            })
        }
        
    })

    return (
        <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
    )

}

/**
* @description 
*/
export const useLayoutContext = () : LayoutContextProps =>{

    const context = useContext(LayoutContext);

    if(!context._inProvider){
        throw new Error("useLayoutContext must be called within a LayoutProvider.");
    }

    return {
        ...context,
        ...makeMemberFunctions(context)
    };

}