import React, {FC, KeyboardEventHandler, ReactElement} from 'react';
import { useCmsXray, useSetCmsXray } from 'cogint-cms-toolkit';
import { useEffect } from 'react';

export type CmsXrayControllerProps = {}

export const CmsXrayController : FC<CmsXrayControllerProps>  = ({children}) =>{

   const setXray = useSetCmsXray();
   const xray = useCmsXray();

    const handleKeyDown : React.KeyboardEventHandler = (e)=>{
        if(e.ctrlKey && e.key === "m") setXray(!xray);
    }

    useEffect(()=>{
        document.addEventListener("keydown", handleKeyDown as unknown as EventListener);
        return ()=>{
            document.removeEventListener("keydown", handleKeyDown as unknown as EventListener);
        }
    })

    return (

        <>
            {children}
        </>

    )

}