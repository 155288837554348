import { ItemStoreI } from "cogint-item-providers-react"
import { pick, difference } from "underscore"

/**
 * Removes items from a slot.
 * @param itemsToRemove 
 * @param slot 
 * @returns 
 */
export const removeFromSlot = (
    itemsToRemove : ItemStoreI,
    slot : ItemStoreI
) : ItemStoreI =>pick(
    slot, 
    difference(
        Object.keys(slot), 
        Object.keys(itemsToRemove)
    )    
)

/**
 * Pushes a map of items at an index.
 * @param itemsToPush 
 * @param slot 
 * @param index 
 * @returns 
 */
export const pushAtIndex = (
    itemsToPush : ItemStoreI,
    slot : ItemStoreI,
    index : number
)=>{

    console.log(itemsToPush, slot, index);

    const keys = difference(Object.keys(slot), Object.keys(itemsToPush));
    const topKeys = keys.slice(0, index);
    const top = pick(slot, topKeys);
    console.log("Top: ", top);

    const bottomKeys = keys.slice(index);
    const bottom = pick(slot, bottomKeys);
    console.log("Bottom: ", bottom);

    console.log({
        ...top,
        value : {},
        ...itemsToPush
    })

    return {
       ...top,
       ...itemsToPush,
       ...bottom
    }
}