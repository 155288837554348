import React, {FC, ReactElement} from 'react';
import { Check2Circle } from 'react-bootstrap-icons';

export type SuccessAlertProps = {
    message : React.ReactNode
}

export const SuccessAlert : FC<SuccessAlertProps>  = ({
    message
}) =>{

    return (

        <div style={{
            width : "100%",
            display : "grid",
            gridTemplateColumns : '1fr 5fr',
            alignContent : "center",
            alignItems : "center"
        }}>
            <div>
                 <Check2Circle size={14}/>
            </div>
            <div>
                {message}
            </div>
        </div>

    )

}