import { ItemI } from 'cogint-item-fundaments';
import React, {FC, ReactElement} from 'react';
import { useContext } from 'react';
import { useReducer } from 'react';
import { createContext } from 'react';

export type CommitContext = {
    commit : (item : ItemI)=>Promise<void>
    remove : (item : ItemI)=>Promise<void>
}

export const DefaultCommitCtx : CommitContext = {
    commit : async ()=>{},
    remove : async ()=>{}
}

export const CommitCtx = createContext(DefaultCommitCtx);

/**
 * Reduces the state of a commit with a setter function.
 * @param state is the state of the commit before the reductino.
 * @param set is a function that sets the state of the commit.
 * @returns the new state of the commit.
 */
 export const commitReducer = (state : CommitContext, set : (state : CommitContext)=>CommitContext)=>set(state);


export type CommitContextControllerProps = {
    commit : (item : ItemI)=>Promise<void>
    remove : (item : ItemI)=>Promise<void>
}

/**
 * Controller for CommitContext.
 * @param param0 
 * @returns 
 */
export const CommitContextController : FC<CommitContextControllerProps>  = ({children, commit, remove}) =>{

    const [state, dispatch] = useReducer(commitReducer, DefaultCommitCtx);

    return (
        <CommitCtx.Provider value={{
            commit : commit,
            remove : remove
        }}>
            {children}
        </CommitCtx.Provider>
    )

}

export const useCommitContext = ()=>{
    const context = useContext(CommitCtx);
    if(!context) throw  new Error("Cannot use commit context outside of a CommitContextController.");
    return context;
}