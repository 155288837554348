import React, {FC, ReactElement} from 'react';
import {Button} from "cntric-component-library";
import { useTheme } from 'Views/Theme';
import { Icon, PersonCircle, FileEarmarkPersonFill, PersonBadgeFill, InfoCircleFill } from 'react-bootstrap-icons';
import { ColorsByName } from 'Views/Theme/ThemeColors';
import { InfoBlock } from 'Views/Components/Info';
import { Intelephant } from 'Views/Components/Logos';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { useFaunaContext } from 'Models/Fauna';
import { useState } from 'react';
import {query as  q, values} from "faunadb";
import { BuildCogintView, MyIdentity, Domains, CogintDelete, CogintViewlikeDoc } from 'cogint-fauna';
import { useAlert } from 'react-alert';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ViewTypes = ["Stakeholder", "Analyst", "Lead"] as const;

export const Views : {
    [key in typeof ViewTypes[number]] : {
        desc : string, 
        Icon : Icon
    }
} = {
    "Stakeholder" : {
        desc : "I'm here to read reports.",
        Icon : PersonCircle
    },
    "Analyst" : {
        desc : "I'm here to write reports.",
        Icon : FileEarmarkPersonFill
    },
    "Lead" : {
        desc : "I'm here to manage this domain.",
        Icon : PersonBadgeFill
    }
}


export const ViewSelectButton : FC<{
    viewName : string, 
    viewDesc : string,
    primaryColor ? : React.CSSProperties["color"],
    secondaryColor ? : React.CSSProperties["color"],
    Icon : Icon,
    selected ? : boolean,
    onSelect? : (viewName : string)=>void
}>= ({
    viewName,
    viewDesc,
    primaryColor,
    secondaryColor,
    Icon,
    selected,
    onSelect
})=>{

    const handleClick = ()=>{
        onSelect && onSelect(viewName);
    }

    return (
        <Button
        invert={selected}
        onClick={handleClick}
        style={{
            width : "300px",
            display : "flex",
            alignContent : "center",
            alignItems : "center"
        }}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}>
            <div style={{
                width : "100%",
                display : "grid",
                gridTemplateColumns : "1fr 3fr",
                alignContent : "center",
                alignItems : 'center',
                fontSize : ".9em"
            }}>
                <div>
                    <Icon size="30px"/>
                </div>
                <div style={{
                    textAlign : "left",
                    verticalAlign : "center",
                    marginBottom : "0px"
                }}>
                    <h6>{viewName}</h6>
                    <span>{viewDesc}</span>
                </div>
            </div>
        </Button>
    )
}

export type EditViewsProps = {
    
}

export const EditViews : FC<EditViewsProps>  = () =>{

    const nav = useNavigate();
    const alert = useAlert();
    const {primaryColor, secondaryColor} = useTheme();
    const {viewNames, domainName, domainId, loaded, viewDocs, refresh} = useDomainContext();
    const { search } = useLocation();
    const returnTo = new URLSearchParams(search).get("returnTo");

    const {faunaClient} = useFaunaContext();

    const [viewsSelected, setViewsSelected] = useState(new Set(viewNames));
    useEffect(()=>{
        setViewsSelected(new Set(viewNames))
    }, [loaded]);

    const onContinue = async ()=>{

        const _viewsSelected = [...viewsSelected].filter((view)=>!viewNames.includes(view)); 

        faunaClient && faunaClient.query<CogintViewlikeDoc[]>(q.Map(
            _viewsSelected,
            q.Lambda(
                "viewName",
                BuildCogintView(MyIdentity(), q.Var("viewName") as string, q.Ref(
                    Domains(),
                    domainId
                )as values.Ref)
            )
        )).then((data)=>{
            refresh();
            alert.success(`Added views ${_viewsSelected.join(", ")}.`)
        }).catch((err)=>alert.error(err.message));

        const _viewsUnselectedDocs = viewDocs.filter((doc)=>!viewsSelected.has(doc.data.type))
        const _viewsUnselectedRefs = _viewsUnselectedDocs.map((doc)=>doc.ref);

        faunaClient && faunaClient.query<CogintViewlikeDoc[]>(q.Map(
            _viewsUnselectedRefs,
            q.Lambda(
                "ref",
                q.Delete(q.Var("ref") as values.Ref)
            )
        )).then((data)=>{
            alert.success(`Removed views ${_viewsUnselectedDocs.map((doc)=>doc.data.type).join(", ")}`)
            refresh();
        }).catch((err)=>alert.error(err.message));

        returnTo && decodeURI(returnTo) !== "/edit/tos" ? nav(decodeURI(returnTo)) : nav("/app"); 

    }


    return (

        <div style={{
            width : "100vw",
            minHeight : "100vh",
            display : "grid",
            alignContent : "center",
            alignItems : "center",
            justifyContent : "center",
            justifyItems : "center",
            paddingTop : "10vh",
            paddingBottom : "10vh",
            background : primaryColor,
            color : secondaryColor
        }}>
           <div style={{
               borderRadius : "10px",
                background : secondaryColor,
                display : "grid",
                justifyItems : "center",
                justifyContent : "center",
                padding : "30px",
                color : ColorsByName["darkGray"]
           }}>
                <Intelephant color={primaryColor}/>
                <h3>First time visiting <span style={{
                    color : primaryColor
                }}>{domainName}</span>?</h3>
                <i>Select all views that apply.</i>
                <br/>
                <div style={{
                    display : "grid",
                    gridTemplateRows : "1fr",
                    gap : "5px"
                }}>
                    {Object.keys(Views).map((viewName)=>{
                        return (
                            <ViewSelectButton
                            selected={viewsSelected.has(viewName)}
                            onSelect={()=>{
                                if(viewsSelected.has(viewName)){
                                    viewsSelected.delete(viewName);
                                    setViewsSelected(new Set([
                                        ...viewsSelected
                                    ]))
                                } else setViewsSelected(new Set([
                                    ...viewsSelected,
                                    viewName
                                ]))
                            }}
                            key={viewName}
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            viewName={viewName}
                            viewDesc={Views[viewName as keyof typeof Views].desc}
                            Icon={Views[viewName as keyof typeof Views].Icon}/>
                        )
                    })}
                     <InfoBlock docsRef="views" type="info" style={{
                        width : "300px",
                        fontSize : "12px"
                    }}>
                       Views do not change the data you can access, only its presentation.
                    </InfoBlock>
                    <div style={{
                        width : "100%",
                        display : "flex",
                        justifyItems : "right",
                        justifyContent : "right"
                    }}>
                        <Button
                        action={onContinue}
                        invert
                        style={{
                            width : "100%"
                        }}
                        primaryColor="green"
                        secondaryColor="white">
                            Continue
                        </Button>
                    </div>
                    <div style={{
                        color : "gray",
                        textAlign : "left",
                        fontSize : "12px"
                    }}>
                        Selected: {viewsSelected.size > 0 ? [...viewsSelected].join(", ") : "None"}
                    </div>
                </div>
           </div>
        </div>

    )

}