import React from 'react';
import 'react-grid-layout/css/styles.css';
import { Router } from "Controllers/Routes/Router";
import './App.css';
import { LoadingPage } from 'Controllers/Pages/LoadingPage';
import { ThemeProvider } from 'Views/Theme';

function App() {

  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
