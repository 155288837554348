import { ItemI } from 'cogint-item-fundaments';
import { values } from 'faunadb';
import React, {FC, ReactElement, useEffect} from 'react';
import { useState } from 'react';
import {ActorRolePrivilegeSet, getPrivilegeName, PrivilegeSetI} from "cogint-react-resource-doc"
import { useFaunaContext } from 'Models/Fauna';
import { CheckPrivilege, CogintRoleDocI, CogintRolesByActorAndItem, CogintUpdate } from 'cogint-fauna';
import { useItemStore } from 'cogint-item-providers-react';
import {query as q} from "faunadb";
import {Items, PrivilegeInfoI} from "cogint-fauna";
import { use } from 'chai';
import { getIcon, LineItem, TabItem } from 'cogint-react-item-namespace';
import { ThroughGlass, GrowOnEvent, Button } from 'cntric-component-library';
import { Spinner } from 'react-bootstrap';
import { ConsoleLogger } from 'typedoc/dist/lib/utils';
import { ActorNode } from './ActorNode';
import { useAlert } from 'react-alert';
import { useTheme } from 'Views/Theme';

export type RoleConfigActorNodeProps = {
    actorRef : values.Ref,
    itemId : string,
    canEdit : boolean
}

export const RoleConfigActorNode : FC<RoleConfigActorNodeProps>  = ({
    actorRef,
    itemId,
    canEdit
}) =>{

    const {primaryColor, secondaryColor} = useTheme();

    const {
        selectItems
    } = useItemStore();
    const actor = selectItems()[actorRef.id]

    const {
        faunaClient
    } = useFaunaContext();

    const alert = useAlert();
    
    const [roleDoc, setRoleDoc] = useState<CogintRoleDocI|undefined|false>(undefined);
    useEffect(()=>{

        if(faunaClient){
            // get the role doc
            faunaClient.query(
                q.If(
                    q.Exists(
                        q.Match(CogintRolesByActorAndItem(), actorRef, q.Ref(Items(), itemId))
                    ),
                    q.Get(q.Match(CogintRolesByActorAndItem(), actorRef, q.Ref(Items(), itemId))),
                    false
                )
            ).then((data)=>{
                setRoleDoc(data as CogintRoleDocI);
            }).catch((e)=>{
                setRoleDoc(false)
            });
           
        }

    }, [faunaClient, actorRef]);


    const setPrivilege = async (
        set : (priv : PrivilegeSetI)=>PrivilegeSetI
    )=>{
        if(faunaClient && roleDoc){
            faunaClient.query<CogintRoleDocI>(
                CogintUpdate(
                    roleDoc.ref,
                    {
                        data : set({
                            rolePrivilege : roleDoc.data.rolePrivilege,
                            itemPrivilege : roleDoc.data.itemPrivilege
                        })
                    }
                )
            ).then((data)=>{
                alert.success(`Added ${getPrivilegeName({
                    itemPrivilege : data.data.itemPrivilege,
                    rolePrivilege : data.data.rolePrivilege
                })} for ${actor.name}.`)
                setRoleDoc(data);
            }).catch((err : Error)=>{
                
                alert.error(err.message);
            });  
        }
    }

    if(!actor||roleDoc === false){
        return (
           <></>
        )
    }

    if(!roleDoc){
        return (
            <div style={{
                width : "100%",
                display : "grid",
                gridTemplateColumns : "1fr",
                justifyContent : "center",
                alignContent : "center"
            }}><Spinner animation="border"/></div>
        )
    }

    return (
        <div style={{
            border : `1px solid ${primaryColor}`,
            borderRadius : "5px",
            padding : "10px",
            position : "relative"
        }}>
      
            
                <ActorRolePrivilegeSet
                style={{
                    position : "relative"
                }}
                actor={<ActorNode actor={actor}/>}
                edit={canEdit}
                setPrivilegeSet={setPrivilege}
                privilegeSet={{
                    rolePrivilege : roleDoc.data.rolePrivilege,
                    itemPrivilege : roleDoc.data.itemPrivilege
                }}/>
        
        </div>
       

    )

}