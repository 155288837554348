import React, {FC, ReactElement} from 'react';
import {
    DocMainItemDemo
} from "cogint-react-doc-item"
import {ItemI} from "cogint-item-fundaments"
import { AddGoogleDocItem, AddDocController, AddNetItem, AddNetController } from 'Controllers/BuiltInWidgets';
import { DocController } from 'Controllers/DocController';
import { IntegratedNet } from 'Controllers/NetworkController/NetworkController';
import { CreateDomainItem, CreateDomainController } from 'Controllers/BuiltInWidgets/CreateDomain';
import { CreateGroupController, CreateGroupItem } from 'Controllers/BuiltInWidgets/CreateGroup/CreateGroup';
import { CreateContainerController, CreateContainerItem } from 'Controllers/BuiltInWidgets/CreateContainer/CreateContainer';
import { DocOrNetController, DocOrNetItem } from 'Controllers/BuiltInWidgets/DocOrNetwork/DocOrNetwork';

export type ItemRouterProps = {
    item : ItemI,
    style ? : React.CSSProperties
}

export const ItemRouter : FC<ItemRouterProps>  = ({
    item
}) =>{

    

    switch (item.itemType) {
        case "Widget" : {
            if(item._id===AddGoogleDocItem._id) return <AddDocController item={item}/> 
            if(item._id===AddNetItem._id) return <AddNetController item={item}/>
            if(item._id===CreateDomainItem._id) return <CreateDomainController item={item}/>
            if(item._id===CreateGroupItem._id) return <CreateGroupController item={item}/>
            if(item._id===CreateContainerItem._id) return <CreateContainerController item={item}/>
            if(item._id===DocOrNetItem._id) return <DocOrNetController item={item}/>
            return <></>
        }
        case "Doc" : {
            return <DocController item={item}/>
        }
        case "Net" : {
            return <IntegratedNet item={item}/>
        }
    } 

    return <></>

}