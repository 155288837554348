import { ItemI } from 'cogint-item-fundaments';
import React, {FC, ReactElement, useReducer} from 'react';
import { getIcon } from 'cogint-react-item-namespace';
import { ActorRolePrivilegeSet, getPrivilegeName, NoPrivilege, PrivilegeSetI, ViewerPrivilege } from 'cogint-react-resource-doc';
import { useFaunaContext } from 'Models/Fauna';
import {query as q, values, errors} from "faunadb";
import { BuildCogintRole, Items } from 'cogint-fauna';
import { ActorNode } from './ActorNode';
import { Button } from 'cntric-component-library';
import { useAlert } from 'react-alert';

export type NewRoleNodeProps = {
    item : ItemI,
    actor : ItemI,
    setActors : (set : (actors : values.Ref[])=>values.Ref[])=>void,
}

export const NewRoleNode : FC<NewRoleNodeProps>  = ({
    item,
    actor,
    setActors
}) =>{

    const {faunaClient} = useFaunaContext();
    const alert = useAlert();

    const newPriv = async ()=>{
        if(faunaClient){
            faunaClient.query<values.Ref>(
                q.Select(["data", "actor"], BuildCogintRole(
                    q.Ref(Items(), item._id) as values.Ref,
                    q.Ref(Items(), actor._id) as values.Ref,
                    NoPrivilege.itemPrivilege,
                    NoPrivilege.rolePrivilege
                ))
            ).then((data)=>{
                setActors((actors)=>[...actors, data]);
                alert.success(`Added ${getPrivilegeName(NoPrivilege)} for ${actor.name} to access ${item.name}.`)
            }).catch((err : errors.BadRequest)=>{
                
                alert.error("Fauna: " + err.description);
            })
        }
    }


    return (
     
        <div style={{
            display : "flex",
        }}>
           <Button 
            style={{
                width : "100%"
            }}
            action={newPriv}
            primaryColor={"green"} secondaryColor={"white"}>
               <div style={{
                   display : 'flex',
                   alignContent : 'center',
                   alignItems : 'center'
               }}>
                   <ActorNode actor={actor}/>&ensp;
                    +  
               </div>
            </Button>
        </div>
    )

}