import { Children } from "react";

export const Components = ["OnLabel", "OffLabel"] as const;
export type Component = typeof Components[number];

/**
 * @description gets the ItemVariant for a given mode.
 * @param mode is the mode in which the Item is being viewd.
 * @param children are the children of the Item, amongst witch variant nodes will be identified.
 * @returns the children which match the display mode.
 * @error if there are duplicate definitions of the variant.
 */
export const getToggleSwitchComponent = (component : Component, children : React.ReactNode)=>{

    if(!children){
        return;
    }

    const matchingItems = children ? 
        Children.toArray(children).filter((child)=>{
            try {   
                return (child as any).type.name === component; // display name is only for storybook
            } catch(e){
                return false;
            }
        }) 
        : "none"

    if(matchingItems.length > 1) {
        throw new Error(`You have provided more than one Item.${component}. If you are working in the broswer, you may need to refresh to see your changes.`)
    }

    return matchingItems[0];

}