import Color from "color";
import { CSSProperties } from "react";

export interface OuterThemeColorsI {
    mode : "standard" | "dark" | "make" | "make-dark" | "custom"
    primaryColor: CSSProperties["color"],
    secondaryColor: CSSProperties["color"],
    tertiaryColor: CSSProperties["color"],
    backgroundColor: CSSProperties["color"],
    successColor: CSSProperties["color"],
    errorColor: CSSProperties["color"],
    warningColor: CSSProperties["color"]
} 

export interface InnerThemeColorsI {
    _primaryColor: CSSProperties["color"],
    _secondaryColor: CSSProperties["color"],
    _tertiaryColor: CSSProperties["color"],
    _backgroundColor: CSSProperties["color"],
    _darkPrimaryColor: CSSProperties["color"],
    _darkSecondaryColor: CSSProperties["color"],
    _darkTertiaryColor: CSSProperties["color"],
    _darkBackgroundColor: CSSProperties["color"],
    successColor: CSSProperties["color"],
    errorColor: CSSProperties["color"],
    warningColor: CSSProperties["color"]
}

export interface ThemeColorsI extends OuterThemeColorsI, InnerThemeColorsI {
}

export const interpretThemeColors = (
    innerTheme: InnerThemeColorsI,
    action : {mode : string, payload?: string | ThemeColorsI}
    ) : OuterThemeColorsI =>{

        switch (action.mode) {

            case ("standard") : {
                return {
                    mode: "standard",
                    ...innerTheme,
                    primaryColor: innerTheme._primaryColor,
                    secondaryColor: innerTheme._secondaryColor,
                    tertiaryColor: innerTheme._tertiaryColor,
                    backgroundColor: innerTheme._backgroundColor
                }
            }

            case ("dark") : {
                return {
                    mode: "dark",
                    ...innerTheme,
                    primaryColor: innerTheme._darkPrimaryColor,
                    secondaryColor: innerTheme._darkSecondaryColor,
                    tertiaryColor: innerTheme._darkTertiaryColor,
                    backgroundColor: innerTheme._darkBackgroundColor
                }
            }

            case ("make") : {
                return {
                    mode: "make",
                    ...innerTheme,
                    primaryColor: Color(action.payload as String).hex(),
                    secondaryColor: innerTheme._secondaryColor,
                    tertiaryColor: Color(action.payload as String).mix(Color(innerTheme._tertiaryColor)).darken(.1).hex(),
                    backgroundColor: Color(action.payload as String).mix(Color(innerTheme._backgroundColor)).lighten(.1).hex()
                }
            }

            case ("make-dark") : {
                return {
                    mode: "make-dark",
                    ...innerTheme,
                    primaryColor: Color(action.payload as String).hex(),
                    secondaryColor: innerTheme._secondaryColor,
                    tertiaryColor: innerTheme._tertiaryColor,
                    backgroundColor: innerTheme._backgroundColor
                }
            }

            case ("custom") : {
                return {
                    ...(action.payload as ThemeColorsI),
                    mode: "custom"
                }
            }

            default : {
                return {
                    mode: "standard",
                    ...innerTheme,
                    primaryColor: innerTheme._primaryColor,
                    secondaryColor: innerTheme._secondaryColor,
                    tertiaryColor: innerTheme._tertiaryColor,
                    backgroundColor: innerTheme._backgroundColor
                }
            }

        }

}

export const ColorsByName = {
    "darkGray" : "#373737",
    "info" : "#1E92F4",
    "warning" : "#FFCC00",
    "error" : "salmon"
} as const;