import { AdderTab } from "Views/Components/Actions/AdderTab";
import { Tab } from "Views/Components/Actions/Tab";
import equal from "deep-equal";
import React, { FC, memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DroppableStateSnapshot, DropResult, ResponderProvided, Draggable } from "react-beautiful-dnd";
import { useTheme } from "Views/Theme";

export type TabContainerProps = {
    style ? : React.CSSProperties,
    items: {
        [key : string] : React.ReactNode
    },
    handleDragEnd?: (result: DropResult, provided: ResponderProvided)=>void,
    handleTabClose?: (e : React.MouseEvent, tabName : string)=>void,
    getActiveitemKey?: ()=> string,
    setActiveitemKey?: (itemName: string)=> void,
    onAdd? : ()=>void
}

export const TabContainer : FC<TabContainerProps>  = ({
    style,
    children,
    items, 
    handleDragEnd=(result: DropResult, provided: ResponderProvided)=>{
        return
    },
    handleTabClose=(e : React.MouseEvent, tabName: string)=>{},
    getActiveitemKey,
    setActiveitemKey,
    onAdd
}) =>{

    const theme = useTheme();
    const {
        tertiaryColor,
        backgroundColor,
        IconSet
    } = theme;
    const {
        View
    } = IconSet;

    const buttonTabStyle : React.CSSProperties = {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderTop: `1px solid ${tertiaryColor}`,
        borderLeft: `1px solid ${tertiaryColor}`,
        borderRight: `1px solid ${tertiaryColor}`,
        borderBottom: `none`
    }

    const activeButtonTab : React.CSSProperties = {
        borderTop: `1px solid ${tertiaryColor}`,
        borderLeft: `1px solid ${tertiaryColor}`,
        borderRight: `1px solid ${tertiaryColor}`,
        borderBottom: `1px solid ${backgroundColor}`,
        position: "relative",
        top: "1px",
        outline: "none"
    }

    const [keys, setKeys] = useState(Object.keys(items));
    const [compItemsLength, setCompItemsLength] = useState(Object.keys(items).length);

    const _getActiveitemKey = getActiveitemKey ? getActiveitemKey : ()=>keys[0];
    const _setActiveitemKey = setActiveitemKey ? setActiveitemKey : ()=>{};

    useEffect(()=>{
        const keysCheck = Object.keys(items);
        
        if(keysCheck.length > keys.length){// if something new in items
            setKeys(keysCheck)
            setCompItemsLength(keysCheck.length); // reset the comparison length 
            _setActiveitemKey(keysCheck[keysCheck.length - 1]); // set the tab index to the last item, the new item
        } else if (!equal(keysCheck, keys)) {
            for(let key of keysCheck){
                if(!keys.includes(key)){
                    setKeys(keysCheck)
                    _setActiveitemKey(key);
                    break;
                }
            }
            // otherwise everything is included so merely a reordering opration took place
        }
    })

    const transformActiveIndexClose = (keys: string[], index: number)=>{
        if(keys.length > 1){
            return index === 0 ?
                    keys[1]
                    : keys[index - 1]
        }
        return _getActiveitemKey();
    }


    const Tabs = Object.keys(items).length ? Object.keys(items).map((itemName : string, i : number)=>
        <Draggable key={itemName} draggableId={itemName} index={i}>
        {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>   
                <Tab 
                id={itemName}
                key={itemName}
                style={(itemName && _getActiveitemKey() === itemName)? {
                        ...buttonTabStyle,
                        ...activeButtonTab
                    }: {...buttonTabStyle,}}
                name={itemName}
                index={i}
                onClick={_setActiveitemKey}
                handleClose={(e : React.MouseEvent, tabName : string, index: number)=>{ 
                        handleTabClose(e, tabName);
                        if(tabName === _getActiveitemKey()){
                            _setActiveitemKey(
                                transformActiveIndexClose(keys, index)
                            )
                        }
                        setCompItemsLength(keys.length - 1)
                    }}>
                        {items[itemName]}
                </Tab>
            </div>)}
        </Draggable>
    ) : []

    return (

        <div style={{
            ...style,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexFlow: "column"
        }}>
            <div style={{
                display: "flex",
                width: "100%",
                overflowY: "visible",
                position: "relative",
                top: "1px"
            }}>
                <DragDropContext
                    onBeforeCapture={()=>{}}
                    onBeforeDragStart={()=>{}}
                    onDragStart={()=>{}}
                    onDragUpdate={()=>{}}
                    onDragEnd={(result: DropResult, provided: ResponderProvided)=>{ 
                        handleDragEnd(result, provided);
                    }}>
                    <Droppable  direction="horizontal" droppableId="tabBar">
                        {(provided, snapshot: DroppableStateSnapshot) => (
                            <div
                            ref={provided.innerRef}
                            style={{
                                display: "flex",
                                overflowX: "auto",
                                overflowY: "visible"
                            }}
                            {...provided.droppableProps}>
                                {Tabs}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <AdderTab 
                onClick={onAdd}
                key="adder"
                style={{
                    width: "40px",
                    height: "100%",
                }}>
                    <div style={{
                        display: "inline-block",
                        width: "100px",
                        height: "120%",
                        position: "absolute",
                        right: "40px",
                        top: "-10px",
                        backgroundImage: `linear-gradient(to right, ${backgroundColor}00, ${backgroundColor}ff)`
                    }}>

                    </div>
                </AdderTab>
            </div>
            <div style={{
                border: `1px solid ${tertiaryColor}`,
                flex: 1,
                width: "100%",
                backgroundColor: backgroundColor,
                padding: "10px",
                borderTopRightRadius: "4px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
            }}>
                {children}
            </div>
        </div>

    )

}