import Color from 'color';
import { useLayoutContext } from "Views/Layouts";
import { BrandToggle } from "Views/Components/Toggles/BrandToggle";
import React, { FC } from 'react';
import { useTheme } from "Views/Theme";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type MatchedToggleProps = {}

/**
 * @description 
 */
export const MatchedToggle : FC<MatchedToggleProps>  = () =>{

 
    const {
        primaryColor,
        backgroundColor,
        paddingMd
    } = useTheme()

    const {
        HeaderRef,
        toggleSideNav
    } = useLayoutContext()

    const handleClick = ()=>{
        toggleSideNav(false);
    }

    return (

        
             <div style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "inherit",
                height: HeaderRef.current?.clientHeight ? HeaderRef.current?.clientHeight : "auto",
                // filter: "sepia(30%)"
            }}>
                <BrandToggle burgerColor={primaryColor} color={primaryColor} onClick={handleClick}/>
            </div>

       
    )

}