import { Button } from "cntric-component-library";
import React, { FC } from 'react';
import { useTheme } from "Views/Theme";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type AdderTabProps = {
    style?: React.CSSProperties,
    onClick?: React.MouseEventHandler
}

/**
 * @description 
 */
export const AdderTab : FC<AdderTabProps>  = ({style, onClick, children}) =>{

    const {
        IconSet,
        backgroundColor,
        primaryColor
    } = useTheme();


    const {
        Add
    } = IconSet

    return (
    
        <Button 
            onClick={onClick}
            style={{
                color: primaryColor,
                border: "none",
                backgroundColor: "#00000000",
            ...style
        }} size="sm">
            <Add size={22}/>
        </Button>

    )

}