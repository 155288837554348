import React, {FC, useState, useEffect} from 'react';
import Color from "color";
import {useTheme} from "Views/Theme";
import Switch from "react-switch";
import { getToggleSwitchComponent } from './getToggleSwitchComponent';
import {
    generateNamedMember
} from "rgfm";
/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type ToggleSwitchProps = {
    label?: string,
    primaryColor?: React.CSSProperties["color"],
    secondaryColor?: React.CSSProperties["color"],
    invert? : boolean,
    disabled?: boolean,
    active?: boolean,
    loading?: boolean,
    size?: "sm" | "lg" | undefined,
    action? : (e? : React.MouseEvent)=>Promise<void>,
    onChange? : (toggleOn : boolean)=>void,
    onMouseEnter? : (e : React.MouseEvent)=>void,
    onMouseLeave? : (e : React.MouseEvent)=>void,
    style?: React.CSSProperties,
    on? : boolean
}

/**
 * @description 
 */
const ToggleSwitch : FC<ToggleSwitchProps> & {
    OnLabel : FC,
    OffLabel : FC
}  = ({
    label, 
    primaryColor, 
    secondaryColor,
    children,
    invert=false,
    disabled=false,
    active=false,
    loading=false,
    size=undefined,
    action=async (e? : React.MouseEvent)=>{return},
    onChange=(toggleOn : boolean)=>{},
    onMouseEnter=(e: React.MouseEvent)=>{},
    onMouseLeave=(e: React.MouseEvent)=>{},
    style,
    on=false
}) =>{

    const [isDisabled, setDisabled] = useState(disabled);
    const [isActive, setActive] = useState(active);
    const [isLoading, setLoading] = useState(loading);
    const [e, setE] = useState<React.MouseEvent|undefined>(undefined)

    useEffect(()=>{
        if(isLoading){
            action(e).then(()=>{
                setDisabled(false);
                setLoading(false);
                setActive(false);
            })
        }
    })

    const {
        primaryColor : themePrimaryColor
    } = useTheme()

    const usedPrimaryColor = primaryColor || themePrimaryColor;
    const usedSecondaryColor = secondaryColor || Color(usedPrimaryColor).lighten(.5).hex();

    const [isHovered, setHovered] = useState(false); 
    const handleMouseEnter = (e : React.MouseEvent)=>{
        setHovered(true);
        onMouseEnter(e);
    }
    const handleMouseLeave = (e : React.MouseEvent)=>{
        setHovered(false);
        onMouseLeave(e);
    }

    const [isToggleOn, setToggleOn] = useState(on);

    const handleToggleSwitch = ()=>{
        setToggleOn(!isToggleOn);
        onChange(!isToggleOn);
    }   

    const OnLabel = getToggleSwitchComponent("OnLabel", children);
    const OffLabel = getToggleSwitchComponent("OffLabel", children);

    return (
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <Switch height={20} onChange={handleToggleSwitch} checked={isToggleOn} onColor={usedPrimaryColor} offColor={usedSecondaryColor} color={usedPrimaryColor}/>
            {isToggleOn&& OnLabel}
            {!isToggleOn&& OffLabel}
        </div>
    )

}

ToggleSwitch.OnLabel = generateNamedMember("OnLabel");
ToggleSwitch.OffLabel = generateNamedMember("OffLabel");

export {ToggleSwitch};