import React, {FC, ReactElement} from 'react';
import { Intelephant } from 'Views/Components/Logos';
import { useTheme } from 'Views/Theme';
import {motion} from "framer-motion";
import Color from 'color';

export type LoadingPageProps = {}

export const LoadingPage : FC<LoadingPageProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();

    return (

        <div style={{
            background : primaryColor,
            height : "100vh",
            width : "100vw",
            display : "grid",
            alignContent : "center",
            alignItems : "center",
            justifyContent : "center",
            justifyItems : "center",
            color : secondaryColor
        }}>
            <div style={{
                 display : "grid",
                 alignContent : "center",
                 alignItems : "center",
                 justifyContent : "center",
                 justifyItems : "center",
            }}>
                <div style={{
                    position : "relative",
                    height : "80px",
                    width : "80px"
                }}>
                    <motion.div  
                    style={{
                        overflow : "visible",
                        borderRadius : "100%",
                        height : "80px",
                        width : "80px",
                        display : 'grid',
                        alignContent : "center",
                        alignItems : "center",
                        position : "absolute",
                        left : 0,
                        top : 0
                    }}
                    transition={{
                        repeat : Infinity, 
                        duration : 2,
                        type : "spring"
                    }}
                    animate={{
                        scale : [1.0, 1.0, 2.0],
                        background : [
                            Color(primaryColor).lighten(.2).hex(), 
                            Color(primaryColor).lighten(.2).hex(),
                            primaryColor
                        ]
                    }}/>
                    <motion.div  
                    style={{
                        overflow : "visible",
                        borderRadius : "100%",
                        height : "80px",
                        width : "80px",
                        display : 'grid',
                        alignContent : "center",
                        alignItems : "center",
                        position : "absolute",
                        left : 0,
                        top : 0
                    }}
                    transition={{
                        repeat : Infinity, 
                        duration : 2,
                        type : "spring"
                    }}
                    animate={{
                        scale : [1.0, 1.0, 1.4],
                        background : [
                            Color(primaryColor).lighten(.8).hex(), 
                            Color(primaryColor).lighten(.5).hex(),
                            primaryColor
                        ]
                    }}/>
                    <motion.div 
                    style={{
                        position : "absolute",
                        left : 0,
                        top : 0
                    }}
                        transition={{repeat : Infinity, duration : 2}}
                        animate={{scale : [1.0, 1.3, 1.0, 1.0]}}>
                            <Intelephant size="80px" color={secondaryColor}/>
                    </motion.div>
                </div>
                <br/>
                <br/>
                <br/>
                <div style={{
                    textAlign : "center"
                }}>
                    <h3>Loading...</h3>
                </div>  
            </div>
        </div>

    )

}