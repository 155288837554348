import React, {FC, ReactElement, useEffect} from 'react';
import {
    Layout,
    useLayoutContext
} from "Views/Layouts";
import { ReducedHeader } from 'Views/Components/Panels/ReducedHeader';
import { Footer } from 'Views/Layouts/Footer';
import { StakeholderHome } from 'Controllers/Workspaces/Stakeholder';
import { Header } from 'Views/Components/Panels/Header';
import { useParams } from 'react-router-dom';
import { StakeholderItemRouter } from 'Controllers/Workspaces/Stakeholder/Item/StakeholderItemRouter';
import { useItemStore } from 'cogint-item-providers-react';

export type StakeholderDashboardProps = {}

const ManageLayout : FC<{}> = ({children})=>{

    const {
        isLoaded,
        displayHeader,
        toggleHeader,
        displayFooter,
        toggleFooter
    } = useLayoutContext();


    useEffect(()=>{
        if(isLoaded && !displayHeader) toggleHeader(true);
        if(isLoaded && !displayFooter) toggleFooter(true);
    })
    
    return (
        <>
            {children}
        </>
    )

}

export const StakeholderDashboard : FC<StakeholderDashboardProps>  = () =>{

    const {id} = useParams();
    const {selectItems} = useItemStore();
    const item = id ? selectItems()[id] : undefined;

    return (

            <Layout>
                <Layout.Header>
                    <Header searchString={""} setSearchString={()=>{}}/>
                </Layout.Header>
                <Layout.Content>
                    <ManageLayout>
                        {id && item ? <div style={{
                            padding : "20px"
                        }}> 
                            <StakeholderItemRouter item={item}/>
                        </div>: <StakeholderHome/>}
                    </ManageLayout>
                </Layout.Content>
                <Layout.Footer>
                    <Footer/>
                </Layout.Footer>
            </Layout>

    )

}