import { useDashboardMode } from 'Models/Dashboard/DashboardContextProvider';
import React, {FC, ReactElement} from 'react';
import { Dropdown } from 'react-bootstrap';
import { ViewTypes } from 'Controllers/Pages/EditViews';
import {Views} from "Controllers/Pages/EditViews"
import { useTheme } from 'Views/Theme';
import {PencilFill} from "react-bootstrap-icons";
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { useNavigate } from 'react-router';
import {Button} from "cntric-component-library";

export type ViewModeSelectionProps = {}

export const ViewModeSelection : FC<ViewModeSelectionProps>  = () =>{

    const {mode, setMode} = useDashboardMode();
    const {viewNames, domainId} = useDomainContext();
    const nav = useNavigate();

    const _viewTypes = viewNames.filter((type)=>type !== mode);
    const {primaryColor, secondaryColor, tertiaryColor} = useTheme();

    const {
        Icon
    } = Views[mode as keyof typeof Views];

    return (

        <Dropdown>
            <Dropdown.Toggle style={{
                minWidth : "150px",
                background : "none",
                border : "none"
            }} size="sm">
                <Button primaryColor={primaryColor} secondaryColor={secondaryColor}>
                    <Icon/>&ensp;{mode}
                </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {_viewTypes.map((type)=>{

                    const {
                        Icon
                    }= Views[type as keyof typeof Views]

                    return <Dropdown.Item size="sm" style={{
                        alignContent : "center",
                        alignItems : "center",
                        color : primaryColor,
                        fontSize : "14px"
                    }} onClick={()=>setMode(type)}>
                        <Icon/>&emsp;{type}
                    </Dropdown.Item>
                })}
                 <Dropdown.Item size="sm" style={{
                        alignContent : "center",
                        alignItems : "center",
                        fontSize : "14px",
                        color : tertiaryColor
                    }} onClick={()=>nav(`/app/d/${domainId}/edit/views?returnTo=${encodeURI(window.location.pathname + window.location.search)}`)}>
                        <PencilFill/>&emsp;Edit available views
                    </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    )

}