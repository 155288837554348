import React, {FC, ReactElement, useState} from 'react';
import { TabContainer } from 'Views/Components/Containers/TabContainer';
import { useItemStore, ItemStoreI } from 'cogint-item-providers-react';
import { MainItem, TabItem, ContainersFC, UsersFC, CommitMethodI, RemoveMethodI} from 'cogint-react-item-namespace';
import { ItemRouter } from 'Models/ItemRouter';
import { removeFromSlot, pushAtIndex } from 'Models/ItemSlots';
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import { RoleController } from 'Controllers/RoleConfig/RoleConfigController';
import { useMemo } from 'react';
import { ItemI } from 'cogint-item-fundaments';
import { useFaunaContext } from 'Models/Fauna';
import { isNumber } from 'underscore';
import { CheckPrivilege, Items } from 'cogint-fauna';
import {query as q, values} from "faunadb";
import { DocOrNetItem } from 'Controllers/BuiltInWidgets/DocOrNetwork/DocOrNetwork';

export type ExplorerProps = {
    style ? : React.CSSProperties,
    mainItemStyle ? : React.CSSProperties,
    mainItemContentStyle ? : React.CSSProperties,
    items : ItemStoreI,
    setActiveItemKey : (key : string)=>void,
    activeItemKey ? : string,
    setItems : (how : (items : ItemStoreI)=>ItemStoreI)=>void,
    Containers ? : ContainersFC,
    Users ? : UsersFC,
    commit ? : CommitMethodI,
    remove ? : RemoveMethodI,
}

export const Explorer : FC<ExplorerProps>  = ({
    style,
    mainItemStyle,
    items,
    activeItemKey,
    setActiveItemKey,
    setItems,
    Containers,
    Users,
    commit,
    remove
}) =>{

    const handleClose = (e : React.MouseEvent, tabName : string)=>{
        setItems((items)=>{
            return removeFromSlot({
                [tabName] : items[tabName]
            }, items)
        })
    }

    const tabItems = Object.keys(items).reduce((agg, itemKey)=>{
        return {
            ...agg,
            [itemKey] : <TabItem item={items[itemKey]}
                        commit={commit}/>
        }
    },{})

    const handleDragEnd = (result: DropResult, provided: ResponderProvided)=>{

        

        if(result.destination && result.destination.index !== undefined)
            setItems((innerItems)=>{
                const pushed = pushAtIndex(
                    { [Object.keys(innerItems)[result.source.index]] : items[Object.keys(innerItems)[result.source.index]] },
                    innerItems,
                    result.destination ? result.destination.index : 0
                )
                
                
                return pushed;
            })
    }

    

    const _setActiveitemKey = (name : string)=>{
        setActiveItemKey(name);
    }

    const getActiveitemKey = ()=>activeItemKey||"";

    const {faunaClient} = useFaunaContext();
    const canEdit = async (item : ItemI) : Promise<boolean> =>{
        return (isNumber(item._id) || isNumber(parseInt(item._id))) && (faunaClient && await faunaClient.query(CheckPrivilege(q.Ref(Items(), item._id) as values.Ref, "write"))) as unknown as boolean;
     }
    

    return (

      <div style={{
          width : "100%",
          padding : "20px",
          position : "relative",
          height  : items[getActiveitemKey()] ? "auto" : "100%",
          ...style
      }}>
            <TabContainer 
            onAdd={()=>{
                setItems((items)=>{
                    return {
                        ...items,
                        [DocOrNetItem._id] : DocOrNetItem
                    }
                });
                setActiveItemKey(DocOrNetItem._id)
            }}
            handleTabClose={handleClose} 
            handleDragEnd={handleDragEnd} 
            items={tabItems}
            setActiveitemKey={_setActiveitemKey}
            getActiveitemKey={getActiveitemKey}>
                {useMemo(()=>items[getActiveitemKey()] ? 
                <MainItem 
                getResourceEdit={canEdit}
                itemStore={items}
                key={items[getActiveitemKey()].name}
                style={mainItemStyle}
                Containers={Containers}
                Users={RoleController}
                commit={commit}
                remove={remove}
                item={items[getActiveitemKey()]}>
                    <ItemRouter item={items[getActiveitemKey()]}/>
                </MainItem> : <div style={{
                    height : "100%",
                    display : "grid",
                    alignContent : "center",
                    alignItems : "center",
                    justifyContent : "center",
                    justifyItems : "center"
                }}>
                    <div>
                        <i>No items selected.</i>
                    </div>                    
                </div>, [items[getActiveitemKey()]])}
            </TabContainer>
      </div>

    )

}