import { Intelephant } from 'Views/Components/Logos';
import { getFileText } from 'Models/lib';
import React, {FC, ReactElement} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTheme } from 'Views/Theme';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Skeleton from "react-loading-skeleton";
import { Button } from 'cntric-component-library';
import {Form, InputGroup} from "react-bootstrap";
import {Check, Circle} from "react-bootstrap-icons";
import Color from "color";
import { useCmsAsset } from 'cogint-cms-toolkit';

export const TERMSOFUSE = "TERMSOFUSE.md";

export type TermsOfUseContentProps = {
    style? : React.CSSProperties,
    agreeable? : boolean,
    onAgree? : ()=>Promise<void>
}

export const TermsOfUseContent : FC<TermsOfUseContentProps>  = ({
    style,
    onAgree, 
    agreeable
}) =>{

    const {primaryColor, secondaryColor, tertiaryColor} = useTheme();
    const {Node, value} = useCmsAsset("tos");
    
    const alert = useAlert();

    const [agreed, setAgreed] = useState(false);

    return (

            <div style={{
                maxWidth : "800px",
                display : "grid",
                gridTemplateColumns : "1fr",
                justifyContent : "center",
                justifyItems : "center",
                paddingBottom : "50px",
                ...style
            }}><div style={{
                paddingTop : "30px",
                paddingBottom : "30px",
                paddingLeft : "50px",
                paddingRight : "50px",
                display : "grid",
                gridTemplateColumns : "1fr",
                alignContent : "center",
                alignItems : "center",
                justifyContent : 'center',
                justifyItems : "center"
            }}><Intelephant style={{
                height : "60px"
            }} color="white"/><div style={{
                verticalAlign : "center"
            }}><h1>Terms of use</h1>{agreeable && <h6 style={{
                color : Color(secondaryColor).mix(Color(primaryColor)).hex()
            }}>Please agree below.</h6>}</div></div>
            <div style={{
                width : "90%",
                alignSelf : "center",
                textAlign : "left",
                padding : "50px",
                borderRadius : "10px",
                backdropFilter : "invert(5%)"
            }}>
                {!Node && <Skeleton width="100%" count={5}/>}
                {Node && <Node div markdown/>}
                {agreeable && <><hr/>
                <div style={{
                    display : "grid",
                    alignContent : "center",
                    alignItems : 'center',
                    justifyContent : "center",
                    justifyItems : "center" 
                }}>
                    <InputGroup style={{
                    display : "flex",
                    alignContent : "center",
                    alignItems : 'center',
                    justifyContent : "center",
                    justifyItems : "center",
                    fontSize : "16px" 
                    }}>
                        <Button 
                        size="sm"
                        style={{
                            padding : "0px",
                            height : "20px",
                            width : "20px",
                            fontSize : "12px"
                        }}
                        onClick={()=>setAgreed(!agreed)}
                        invert={!agreed}
                        primaryColor="white" secondaryColor="green">
                            {agreed ? <>&#10003;</> : <Circle style={{
                                opacity : 0
                            }}/>}
                        </Button>&ensp;
                        I agree to the terms of use.
                    </InputGroup>
                    <br/>
                    <Button 
                    action={async ()=>{agreed && onAgree && await onAgree()}}
                    disabled={!agreed}
                    primaryColor={secondaryColor} secondaryColor={primaryColor}>Submit</Button>
                </div></>}
            </div></div>

    )

}