import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'cntric-component-library';
import { Intelephant } from 'Views/Components/Logos';
import React, {FC, ReactElement} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from 'Views/Theme';
import {motion} from "framer-motion";
import { AboutContent } from 'Controllers/Workspaces/About/AboutContent';
import { useState } from 'react';
import { useCmsAsset } from 'cogint-cms-toolkit';

export type LandingPageProps = {}

export const LandingPage : FC<LandingPageProps>  = () =>{

    const {
        primaryColor,
        secondaryColor
    } = useTheme();

    const {
        loginWithRedirect,
   } = useAuth0();

    const [about, setAbout] = useState(false);

    const handleLoginClick = ()=>{
        loginWithRedirect({
            login_hint : "sign_up",
            appState : {
                returnTo : "/app",
            }
        })
    };

    const handleSignUpClick = ()=>{
        loginWithRedirect({
            screen_hint  : "signup",
            appState : {
                returnTo : "/app",
            }
        })
    };

    const {Node : AboutCms} = useCmsAsset("about", "About");
    const {Node : TermsCms} = useCmsAsset("terms", "Terms");

    const nav = useNavigate();

    return (

        <div>
             <div style={{
            height : "100vh",
            width : "100vw",
            display : 'grid',
            alignContent : "center",
            alignItems : 'center',
            justifyContent : "center",
            justifyItems : "center",
            gridTemplateRows : "1fr 1fr 1fr",
            background : primaryColor,
            color : "white"
        }}>
            <div>{/**Nothing yet */}</div>
            <motion.div style={{
                display : "grid",
                justifyContent : "center",
                justifyItems : "center",
            }} transition={{duration : 1.0}}
            animate={{
                opacity : [0, 1],
            }}>
                <div><Intelephant size={100} color="white"/></div><h1>cogint</h1> 
                <div style={{
                    width : "300px",
                    /*display : "flex",
                    justifyContent : "center",
                    justifyItems : "center",
                    gap : "5%"*/
                }}>
                    <Button
                    onClick={handleLoginClick}
                    primaryColor={"white"}
                    secondaryColor={primaryColor}
                    >Go</Button>
                </div>
                </motion.div>
                <motion.div 
                transition={{duration : 1.0}}
                animate={{
                    opacity : [0, 0, 1]
                }}
                style={{
                    display : "grid",
                    gridTemplateColumns : "1fr 1fr"
                }}>
                    <div style={{
                        borderRight : "1px solid white",
                        paddingRight : "10px",
                        textAlign : "right",
                        overflow : "hidden"
                    }}>
                        <motion.div
                        transition={{duration : 1.5}}
                        animate={{
                            x : [
                                100, 100, 0
                            ]
                        }}>
                            <span style={{
                                color : "#C8A2C8",
                                textDecoration : "none"
                            }}>#</span>
                            <a style={{
                                color : "white"
                            }} href={`#about`} onClick={(e)=>{
                                setAbout(true);
                            }}><AboutCms/></a>
                        </motion.div>
                    </div>
                    <div style={{
                        paddingLeft : "10px",
                        textAlign : "left",
                        overflow : "hidden"
                    }}>
                        <motion.div 
                        transition={{duration : 2.0}}
                        animate={{
                            x : [
                                -100, -100, -100, 0
                            ]
                        }}>
                            <Link to="/terms" style={{
                                color : "white"
                            }}><TermsCms/></Link>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
           {about && <div style={{
                 background : primaryColor,
                 color : secondaryColor,
                 width : "100vw",
                 minHeight : "100vh",
                 display : "grid",
                 gridTemplateColumns : "1fr",
                 justifyContent : "center",
                 justifyItems : "center"
            }}>
                 <motion.div id="about"  transition={{duration : 1.0}}
                    animate={{
                        opacity : [0, 0, 1]
                    }}>
                    <AboutContent/> 
                </motion.div>
            </div>}
        </div>
        

    )

}