import React, {FC, ReactElement} from 'react';
import {AddGoogleDoc} from "cogint-add-gdoc-widget";
import { useTheme } from 'Views/Theme';
import { Item, ItemI } from 'cogint-item-fundaments';
import {Client, CurrentIdentity, values} from "faunadb";
import { CheckPrivilege, MyIdentity, DocDocI, NewContainer } from 'cogint-fauna';
import { CommitMethodI } from 'cogint-react-item-namespace';
import { useItemStore, commit } from 'cogint-item-providers-react';
import { useFaunaContext } from 'Models/Fauna';
import { useItemSlotsContext } from 'Models/ItemSlots';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import {Button} from "cntric-component-library";
import { useState } from 'react';
import { LinkEnabledInput } from "cogint-ontology-link";
import {GithubPicker} from "react-color";

export const createContainer = async (
    client : Client,
    name : string,
    color : React.CSSProperties["color"],
    commit : CommitMethodI,
    addToSlot : (item : ItemI)=>void
)=>{

    const doc =  await client.query<DocDocI>(NewContainer({
        name : name,
        color : color
    }));

    await commit(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Container"
    }));

    addToSlot(Item({
        _id : doc.data.item.id,
        name : name,
        itemType : "Container"
    }));


}

export const CreateContainerItem = Item({
    _id : "create-container",
    name : "widgets/Create New Container",
    itemType : "Widget"
})

export type CreateContainerControllerProps = {
    item : ItemI
}

export const CreateContainerController : FC<CreateContainerControllerProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();

    const [name, setName] = useState("");
    const [color, setColor] = useState<string>(primaryColor as unknown as string);

    const storeContext = useItemStore();
    const {faunaClient} = useFaunaContext();
    const _commit = async (item : ItemI)=>{
        await commit(storeContext, {[item._id] : item});
    }

    const {
        setItemSlots,
    } = useItemSlotsContext();

    const addSlots = (item : ItemI)=>{
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlot : {
                    ...slots.mainSlot,
                    [item._id] : item
                }
            }
        })
    }

    const _createContainer = (name : string, color : React.CSSProperties["color"])=>{
        faunaClient && createContainer(
            faunaClient,
            name,
            color,
            _commit,
            addSlots
        )
    };


    return (

       
        <Form style={{
            width : "100%"
        }}>
            <h6>Container name: </h6>
            <Form.Control placeholder="Enter name" onChange={(e)=>{
                setName(e.target.value);
            }} type="text" />
            <br/>
            <h6>Container color: </h6>
            <LinkEnabledInput 
                wantsGhostText={()=>true}
                type={"text"} value={color}>
                {CSS.supports("color", color) && <>
                &ensp;
                <div
                className="hover-invert-sm" 
                style={{
                    border : "1px solid gray",
                    position : "relative",
                    top : "2px",
                    height : "12px",
                    width : '12px',
                    background : color,
                    display : "inline-block",
                    alignContent : "center",
                    alignSelf : "center"
                }}/></>}
            </LinkEnabledInput>
            <GithubPicker
            width="100%"
            onChange={(color)=>setColor(color.hex)}/>
            <br/>
            <div style={{
                width : "100%",
                display : "flex",
                flexDirection : "row-reverse"
            }}>
                <Button
                style={{
                    width : "100%"
                }}
                primaryColor={primaryColor} secondaryColor={secondaryColor} action={async ()=>{
                    _createContainer(name, color);
                }}>
                    Create Container
                </Button>
            </div>
        </Form>

    )

}