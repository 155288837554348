import { CogintViewlikeDoc, CogintViewsByUserAndDomain, DomainDocI, DomainFromItemRef, Items, PublicDomainName } from 'cogint-fauna';
import { useFaunaContext } from 'Models/Fauna';
import React, {FC, ReactElement} from 'react';
import { createContext } from 'react';
import { useEffect } from 'react';
import {useParams} from "react-router-dom";
import { GetMyViewsForDomain, Domains, PublicDomainId } from 'cogint-fauna';
import {query as q, values} from "faunadb";
import { useReducer } from 'react';
import { useContext } from 'react';
import { useTheme } from 'Views/Theme';

export type DomainContext = {
    /** the id of the domain. */
    domainId : string,
    /** the name of the domain. */
    domainName : string,
    /** whether or not the provided domain exists. */
    domainExists : boolean,
    /** the documents for the views. */
    viewDocs : CogintViewlikeDoc[],
    /** the names of views enabled for the domain. */
    viewNames : string[],
    /** whether the domain context has loaded. */
    loaded : boolean,
    /** sets the DomainContext state. */
    dispatch : React.Dispatch<(state: DomainContext) => DomainContext>
    /** forces the domain context to refresh. */
    refresh : ()=>void
}

/**
 * By default the domain name should be root
 * and the views should be empty.
 */
export const DefaultDomainContext : DomainContext = {
    domainId : PublicDomainId,
    domainName : PublicDomainName,
    domainExists : true,
    viewDocs : [],
    viewNames : [],
    loaded : false,
    dispatch : ()=>{},
    refresh : ()=>{} 
} 

export const DomainCtx = createContext(DefaultDomainContext);

/**
 * Reduces the state of a domain with a setter function.
 * @param state is the state of the domain before the reductino.
 * @param set is a function that sets the state of the domain.
 * @returns the new state of the domain.
 */
export const domainReducer = (state : DomainContext, set : (state : DomainContext)=>DomainContext)=>set(state);

export type DomainContextProps = {
    handleError ? : (err : Error)=>void
}

/**
 * Provides access to DomainContext. Handles view fetching for DomainContext.
 * @param param0 
 * @returns 
 */
export const DomainContextProvider : FC<DomainContextProps>  = ({children, handleError}) =>{

    const [state, dispatch] = useReducer(
        domainReducer,
        DefaultDomainContext
    );

    const [tick, refresh] = useReducer(x=>x+1, 0);

    // keep the domain up to date with the location
    const {domain} = useParams();
    const _domain = domain||PublicDomainId;
    const {faunaClient} = useFaunaContext();
    const theme = useTheme();
    useEffect(()=>{

        // we should look to make this one round trip
        faunaClient && faunaClient.query<DomainDocI>(q.Get(q.Ref(Domains(), _domain) as values.Ref))
        .then((data)=>{
            
            dispatch((state)=>({
                ...state,
                domainExists : true,
                domainName : data.data.name
            }));
            theme.dispatchTheme({
                type : "def",
                payload : {
                    ...theme,
                    primaryColor : data.data.color,
                    _primaryColor : data.data.color
                }
            })
        }).catch((err)=>{
            
            dispatch((state)=>({
                ...state,
                domainExists : false
            }))
        });

        faunaClient && faunaClient.query<CogintViewlikeDoc[]>(GetMyViewsForDomain(
                q.Ref(
                    Domains(),
                    _domain
                ) as values.Ref
            )).then((views)=>{
                dispatch((state)=>{
                    return {
                        ...state,
                        domainId : _domain,
                        viewDocs : views,
                        viewNames : [...(new Set(views.map((view)=>view.data.type)))],
                        loaded : true
                    }
                })
            }).catch((err)=>{
                handleError && handleError(err);
            });

        }, [domain, faunaClient, tick])

    return (

        <DomainCtx.Provider value={{
            ...state,
            dispatch : dispatch,
            refresh : refresh
        }}>
            {children}
        </DomainCtx.Provider>

    )

}

/**
 * Gives read-only access to domain context.
 * @returns the current domain context.
 */
export const useDomainContext = () : Readonly<DomainContext> =>{
    const context = useContext(DomainCtx);
    if(!context) throw new Error("useDomainContext must be called from within a context provider.");
    return context;
}