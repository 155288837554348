import React, {
    createContext, FC, useContext, useReducer
} from 'react';


export interface ArbitraryStoreContextProps {
    entries : {
        [key : string] : any
    }
    dispatch: (action : {
        type : string,
        payload : any
    })=>void
    _inProvider: boolean,
}


export const ArbitraryStoreDefaultContext : ArbitraryStoreContextProps = {
    entries : {},
    dispatch: (action : {
        type : string,
        payload : any
    })=>{},
    _inProvider: true,
}

export const ArbitraryStoreOutsideProviderContext : ArbitraryStoreContextProps = {
    ...ArbitraryStoreDefaultContext,
    _inProvider: false,
}

export const ArbitraryStoreContext = createContext(ArbitraryStoreOutsideProviderContext);

/**
* @description The used to modify the state of the ArbitraryStoreContext
*/
export const ArbitraryStoreReducer = (state : ArbitraryStoreContextProps, action : {
    type : string,
    payload : any
} )=>{
    switch (action.type) {

        case ("insert") : {

            return {
                ...state, 
                entries : {
                    ...state.entries,
                    ...action.payload
                }
            }
        }

        default : {

            return {
                ...state,
                ...action.payload,
                entries : {
                    ...state.entries,
                    ...action.payload.entries
                }
            } as ArbitraryStoreContextProps
        }

    }
}


export type ArbitraryStoreProviderProps = {
    
}


export const ArbitraryStoreProvider : FC<ArbitraryStoreProviderProps>  = ({children}) =>{

    const [state, dispatch] = useReducer(ArbitraryStoreReducer, ArbitraryStoreDefaultContext);

    
    const realState = {
        ...state,
        dispatch : dispatch
    }

    return (
        <ArbitraryStoreContext.Provider value={realState}>{children}</ArbitraryStoreContext.Provider>
    )

}

/**
* @description 
*/
export const useArbitraryStoreContext = () : ArbitraryStoreContextProps =>{

    const context = useContext(ArbitraryStoreContext);

    if(!context._inProvider){
        throw new Error("useArbitraryStoreContext must be called within a ArbitraryStoreProvider.");
    }

    return {
        ...context
    };

}