import React, {useReducer, createContext, FC, useContext, useEffect} from "react";
import {Client} from "faunadb";
import {useAuth0} from "@auth0/auth0-react"
import { useAlert } from "react-alert";
interface FaunaContextI {
    faunaClient?  : Client,
    setFauna : React.Dispatch<(items: FaunaContextI) => FaunaContextI>
}
const FaunaContext : React.Context<FaunaContextI> = createContext({setFauna : ()=>{}} as FaunaContextI);
const itemsReducer = (state : FaunaContextI, set : (items : FaunaContextI)=>FaunaContextI)=>set(state);
/**
 * Provides access to a Fauna client throughout the application.
 * @param param0 
 * @returns 
 */
export const FaunaProvider : FC  = ({children})=>{
    
    const [state, setFauna] = useReducer(itemsReducer, {setFauna: ()=>{}});
    const {getAccessTokenSilently} = useAuth0();
    const alert = useAlert();

    useEffect(()=>{
        getAccessTokenSilently().then((secret)=>{
            setFauna((fauna)=>{
                return {
                    ...fauna,
                    faunaClient : new Client({
                        secret : secret,
                        scheme : "https",
                        domain : "db.us.fauna.com"
                    })
                }
            });
            alert.success("Retrieved access token!");
        }).catch((err : Error)=>{
            alert.error(err.message);
        })
    }, [])

    return <FaunaContext.Provider value={{
        ...state,
        setFauna : setFauna
    }}>
        {children}
    </FaunaContext.Provider>

}
/**
 * Hook for using Fauna context.
 * @returns 
 */
export const useFaunaContext = () : FaunaContextI=>{

    const context = useContext(FaunaContext);
    if(!context){
        throw new Error("useFaunaContext must be called within a FaunaProvider.");
    }
    return context;

}