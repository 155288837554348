import React, {FC, ReactElement} from 'react';
import { useState } from 'react';

export type ContainerWrapperProps = {
    color ? : React.CSSProperties["color"],
    onClick ? : ()=>void,
    name : string,
    dropdownHeight ? : React.CSSProperties["height"],
    isSelected ? : boolean, 
    style ? : React.CSSProperties
}

export const ContainerWrapper : FC<ContainerWrapperProps>  = ({
    isSelected = false,
    name,
    style,
    color = "black", 
    onClick,
    children,
    dropdownHeight = "80px"
}) =>{

    return (

        <div 
        onClick={onClick}
        className="hover-invert-sm"
        style={{
            borderRadius : "10px",
            border : `1px solid ${color}`,
            textAlign : "left",
            padding : "10px",
            cursor : "pointer",
            ...style
        }}>
            <h5>{name}</h5>
            <div style={{
                height : isSelected ? "auto" : dropdownHeight,
                overflow : "hidden"
            }}>
                {children}
            </div>
        </div>

    )

}