import { ContainerDocI, ContainerFromItemRef, Items } from "cogint-fauna";
import {ItemI} from "cogint-item-fundaments";
import { itemReducer, ItemStoreI } from 'cogint-item-providers-react';
import { useFaunaContext } from "Models/Fauna";
import React, {FC, ReactElement, useEffect} from 'react';
import { useState } from "react";
import { ContainerWrapper } from "./ContainerWrapper";
import {query as q, values} from "faunadb";
import { useAlert } from "react-alert";
import { ContainerItemController } from "./ContainerItemController";

export type ContainerControllerProps = {
    containerItem ? : ItemI,
    storeItems : ItemStoreI,
    onClick ? : ()=>void
}

export const ContainerController : FC<ContainerControllerProps>  = ({
    containerItem,
    storeItems,
    onClick
}) =>{

    const {faunaClient} = useFaunaContext();
    const alert = useAlert();

    const containedItems = containerItem ? Object.values(storeItems)
    .filter((item)=>{
        return item.relatives.includes(containerItem._id)
    }) : Object.values(storeItems).filter((item)=>{
        return item.relatives.length < 1;
    });

    const [containerDoc, setContainerDoc] = useState<undefined | ContainerDocI>(undefined);
    useEffect(()=>{
        if(faunaClient && containerItem){
            faunaClient.query<ContainerDocI>(ContainerFromItemRef(
                q.Ref(Items(), containerItem._id) as values.Ref
            )).then((data)=>setContainerDoc(data))
            .catch((err)=>alert.error(err.message));
        }
    }, [faunaClient])

    return (

        <ContainerWrapper
        onClick={onClick} 
        color={containerDoc && containerDoc.data.color}
        name={containerItem ? containerItem.name : "Untagged data"}>
            {containedItems.map((item)=>{
                return <ContainerItemController item={item}/>;
            })}
        </ContainerWrapper>

    )

}