import React, {FC, ReactElement} from 'react';
import {ItemI} from "cogint-item-fundaments";
import { useFaunaContext } from 'Models/Fauna';
import { useEffect } from 'react';
import { DocDocI, DocFromItemRef, Docs, GetPublishedDoc, Items } from 'cogint-fauna';
import {query as q, values} from "faunadb";
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Skeleton from "react-loading-skeleton";

export type DocItemProps = {
    item : ItemI
}

export const DocItem : FC<DocItemProps>  = ({
    item
}) =>{

    const {faunaClient} = useFaunaContext();
    const alert = useAlert();
    const [doc, setDoc] = useState<DocDocI|undefined>(undefined);
    useEffect(()=>{
        if(faunaClient){
            faunaClient.query<DocDocI>(DocFromItemRef(q.Ref(Items(), item._id) as values.Ref))
            .then((data)=>{
                setDoc(data);
            }).catch((err)=>{
                alert.error(err.message);
            })
        }
    }, [faunaClient]);

    const [docContent, setDocContent] = useState<string|undefined>(undefined)
    useEffect(()=>{
        if(doc && faunaClient){
            faunaClient.query<string>(GetPublishedDoc(q.Ref(Docs(), doc.ref.id) as values.Ref))
            .then((data)=>{
                
                setDocContent(data)
            }).catch((err)=>{
                alert.error(err.message);
            })
        }
    }, [doc, faunaClient])

    return (

        <div style={{
            display : "grid",
            textAlign : "left"
        }}>
            <h3>{item.name}</h3>
            <hr/>
            {docContent === undefined ? <Skeleton count={5}>

            </Skeleton> : <div 
                {...docContent && {dangerouslySetInnerHTML : {__html : docContent}}}>
            
            </div>}
        </div>

    )

}