import React, { FC } from 'react';
import { Bug, FileFill, Pen, CardText } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import {
    useTheme
} from "Views/Theme";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type FooterProps = {}

/**
 * @description 
 */
export const Footer : FC<FooterProps>  = () =>{

    const {
        primaryColor,
        paddingMd,
        backgroundColor, 
        tertiaryColor
    } = useTheme();

    const nav = useNavigate();

    const year = new Date().getFullYear();

    return (

        <div style={{
            borderTop: `1px solid ${primaryColor}`,
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "3fr 4fr 3fr",
            alignItems: 'center',
            fontSize: "10px",
            paddingLeft: paddingMd,
            paddingRight: paddingMd,
            backgroundColor: backgroundColor,
            color: tertiaryColor
        }}>
            <div style={{
                textAlign: "left"
            }}>
               Copyright © {year}, Concentric.io
            </div>
            <div>
                
            </div>
            <div style={{
                textAlign : "right",
                color : tertiaryColor
            }}>
                <Pen/>&nbsp;
                <a href={window.location.hostname + "/docs/tos"} style={{
                    color : tertiaryColor
                }}>
                    Terms of Use
                </a>&ensp;| &ensp;
                <CardText/>&nbsp;
                <a href={window.location.hostname + "/docs"}  style={{
                    color : tertiaryColor
                }}>
                    Docs
                </a>
                &ensp;| &ensp;
                <Bug/>&nbsp;
                <a href={window.location.hostname + "/bug"}  style={{
                    color : tertiaryColor
                }}>
                    Report a Bug
                </a>
            </div>
        </div>

    )

}