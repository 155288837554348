import { Intelephant } from 'Views/Components/Logos';
import { getFileText } from 'Models/lib';
import React, {FC, ReactElement} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTheme } from 'Views/Theme';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Skeleton from "react-loading-skeleton";
import { TermsOfUseContent } from 'Controllers/Workspaces';

export const TERMSOFUSE = "TERMSOFUSE.md";

export type TermsOfUseProps = {}

export const TermsOfUse : FC<TermsOfUseProps>  = () =>{

    const {primaryColor, secondaryColor} = useTheme();
    const alert = useAlert();
    const [termsOfUse, setTermsOfUse] = useState<string|undefined>(undefined);
    useEffect(()=>{
        getFileText({
            owner : "cntric",
            repo : "cogint-pub",
            branch : "main",
            path : TERMSOFUSE
        }).then((data)=>{
            setTermsOfUse(data);
        }).catch((err)=>{
            alert.error(err);
        })
    }, [])

    return (

        <div style={{
            background : primaryColor,
            color : secondaryColor,
            width : "100vw",
            minHeight : "100vh",
            display : "grid",
            gridTemplateColumns : "1fr",
            justifyContent : "center",
            justifyItems : "center"
        }}>
           <TermsOfUseContent/>
        </div>

    )

}