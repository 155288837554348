import { LoadingPage } from 'Controllers/Pages/LoadingPage';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { useMyIdentityContext } from 'Models/MyIdentity/MyIdentityContextProvider';
import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Asserts whether the current pathname is the editTos path.
 * @param path 
 * @returns 
 */
export const isEditMyIdentity = (path : string)=>{
    return path.match(/app\/d\/\d\/edit\/tos/) !== null;
}

export type MyIdentityGuardControllerProps = {}

export const MyIdentityGuardController : FC<MyIdentityGuardControllerProps>  = ({children}) =>{

    const {myIdentity} = useMyIdentityContext();

    return (

        myIdentity ? <>{children}</> : <LoadingPage/>

    )

}