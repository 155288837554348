import { ToSContextProvider } from 'Models/ToS/ToSContextProvider';
import React, {FC, ReactElement} from 'react';
import { useAlert } from 'react-alert';

export type ToSContextControllerProps = {}

/**
 * Controller for ToSContext.
 * @param param0 
 * @returns 
 */
export const ToSContextController : FC<ToSContextControllerProps>  = ({children}) =>{

    const alert = useAlert();

    const handleError  = (err : Error)=>{
        
        alert.error(err.message);
    }
    

    return (

        <ToSContextProvider handleError={handleError}> 
            {children}
        </ToSContextProvider>

    )

}