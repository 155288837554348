import { NetDocI, UpdateNetDoc, Items, NetFromItemRef, Nets, attemptTo, CheckPrivilege } from 'cogint-fauna';
import { ItemI } from 'cogint-item-fundaments';
import { Client, values } from 'faunadb';
import React, {FC, ReactElement} from 'react';
import {query as q} from "faunadb";
import { useFaunaContext } from 'Models/Fauna';
import { useState } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'Views/Theme';
import {useGapi} from "hapi-gapi-toolkit";
import {NetworkDiagramProps} from "netted";
import {NetworkItem} from "cogint-react-item-network";
import {to} from "await-to-js";
import { useAlert } from 'react-alert';
import { CogintUpdate } from 'cogint-fauna';
import { useRef } from 'react';
import { generate } from 'shortid';
import { useItemStore } from 'cogint-item-providers-react';
import { useNavigate } from 'react-router-dom';
import { useItemSlotsContext } from 'Models/ItemSlots';

/**
 * 
 * @param client 
 * @param itemId 
 */
export const getNet = async (
    client : Client,
    itemId : string
) : Promise<NetDocI | false> =>{

    return await client.query<NetDocI>(
        q.If(
            q.And(
                q.IsRef(q.Ref(Items(), itemId)),
                q.Exists(q.Ref(Items(), itemId))
            ),
            NetFromItemRef(q.Ref(Items(), itemId) as values.Ref),
            false
        )
    ) as NetDocI | false
}

export const publishNet = async (
    client : Client,
    docId : string,
    nodes : NetworkDiagramProps["nodes"],
    edges : NetworkDiagramProps["edges"]
)=>{

    return await client.query(CogintUpdate(
        q.Ref(Nets(), docId) as values.Ref,
        {
           data : {
                nodes : nodes,
                edges : edges
           }
        }
    ))
}

export type IntegratedNetProps = {
    item : ItemI
}

export const IntegratedNet : FC<IntegratedNetProps>  = ({
    item
}) =>{

    const alert = useAlert();
    const {selectItems} = useItemStore();
    const {setItemSlots} = useItemSlotsContext();

    const nav = useNavigate();
    const handleLinkClick = (id :string)=>{
        nav(`/app/d/${id}`);
        setItemSlots((slots)=>{
            return {
                ...slots,
                mainSlotKey : id,
                mainSlot : {
                    ...slots.mainSlot,
                    [id] : selectItems()[id]
                }
            }
        });
    }

    const {
        primaryColor,
        secondaryColor,
        tertiaryColor
    } = useTheme();

    const [dataNet, setDataNet] = useState<NetDocI|false>(false);
    const {
        faunaClient
    } = useFaunaContext();
    useEffect(()=>{
        if(faunaClient) getNet(faunaClient, item._id)
        .then((data)=>setDataNet(data));
    }, [faunaClient]);

    const [edit, setEdit] = useState(false);
    useEffect(()=>{
        faunaClient && faunaClient.query<boolean>(CheckPrivilege(
            q.Ref(Items(), item._id) as values.Ref,
            "write"
        )).then((edit)=>setEdit(edit))
    }, [])

    const queue = useRef<string[]>([]);

    if(!dataNet) return <div style={{
        display : "grid",
        width : "100%",
        alignContent : 'center',
        justifyContent :"center",
        justifyItems : "center"
    }}><Spinner animation="border"/></div>

    /**
     * Publishes a net to fauna.
     * @param nodes are the updated network nodes.
     * @param edges are the upated network edges.
     * 
     * It may be in our interest to remove the request queue:
     * https://codereview.stackexchange.com/questions/272647/should-i-replace-this-request-queue
     */
    const _publishNet = faunaClient ? async (
        nodes : NetworkDiagramProps["nodes"],
        edges : NetworkDiagramProps["edges"]
    )=>{

        const pid = generate();
        queue.current.push(pid);
        let i = 10;
        while(queue.current[0] !== pid && i < 100) {
            await new Promise((resolve)=>{
                setTimeout(()=>resolve(true), 50)
            });
            ++i;
        }
        const [err, res] = await to(attemptTo(async ()=>{
            const res = await publishNet(faunaClient, dataNet.ref.id, nodes, edges);
            queue.current.shift();
            return res;
        }));
        if(err) alert.error("You do not have permission to publish this document.");
    } : undefined;


    return (

        <NetworkItem
        edit={edit}
        onAtLinkClick={handleLinkClick}
        items={selectItems()}
        style={{
            width : "100%"
        }}
        nodes={dataNet.data.nodes}
        edges={dataNet.data.edges}
        publishNetwork={_publishNet}/>

    )

}