import { LoadingPage } from 'Controllers/Pages/LoadingPage';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


/**
 * Asserts whether the current pathname is the editTos path.
 * @param path 
 * @returns 
 */
 export const isEditViews = (path : string)=>{
    return path.match(/app\/d\/\d\/edit\/views/) !== null;
}

export type DomainGuardControllerProps = {}

export const DomainGuardController : FC<DomainGuardControllerProps>  = ({children}) =>{

    const {viewNames, domainId, loaded} = useDomainContext();
    
    const nav = useNavigate();
    useEffect(()=>{
        if(viewNames.length < 1 && loaded && !isEditViews(window.location.pathname)) nav(`/app/d/${domainId}/edit/views?returnTo=${encodeURI(window.location.pathname + window.location.search)}`); 
    }, [viewNames])

    return (

        loaded ? <>{children}</> : <LoadingPage/>

    )

}