import React, {FC, ReactElement} from 'react';
import { getIcon} from 'cogint-react-item-namespace';
import { ItemI } from 'cogint-item-fundaments';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';

export type ContainerItemControllerProps = {
    style ? : React.CSSProperties,
    item : ItemI
}

export const ContainerItemController : FC<ContainerItemControllerProps>  = ({
    style,
    item
}) =>{

    const Icon = getIcon(item);
    const {domainId} = useDomainContext();

    return (

        <div style={{
            display : "flex",
            ...style
        }}>
            <div>
                <Icon/>
            </div>
            <div>
                &ensp;
                <a href={`/app/d/${domainId}/dashboard/${item._id}`}>{item.name}</a>
            </div>
        </div>

    )

}