import { Intelephant } from "Views/Components/Logos/Intelephant";
import React, { FC } from 'react';
import { useTheme } from "Views/Theme";

/**
 * @initialAuthor
 * @contributors
 */

/**
 * @description
 */
export type BrandToggleProps = {
    size? : React.CSSProperties["height"],
    onClick? : React.MouseEventHandler,
    color? : React.CSSProperties["color"],
    burgerColor? : React.CSSProperties["color"]
}

/**
 * @description 
 */
export const BrandToggle : FC<BrandToggleProps>  = ({
    size="24px",
    onClick, 
    color,
    burgerColor
}) =>{

    const {
        IconSet,
        paddingMd,
        backgroundColor,
        primaryColor
    } = useTheme();

    const {
        Menu
    } = IconSet;

    return (

        <div style={{
            display: "grid",
            gridTemplateColumns: `1fr 10fr`,
            height: `calc(${size} * 2)`,
            alignItems: "center",
            alignContent: "center"
        }}>
            <div style={{
                justifySelf: "left",
                cursor: "pointer"
            }}>
                <Menu onClick={onClick} size={size} color={burgerColor||primaryColor}/>
            </div>
            <div style={{
                alignContent: "left",
                textAlign: "left",
                width : "100%",
                padding: paddingMd
            }}>
                <Intelephant color={primaryColor} label/>     
            </div>
        </div>

    )

}