import { Intelephant } from 'Views/Components/Logos';
import { getFileText } from 'Models/lib';
import React, {FC, ReactElement} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTheme } from 'Views/Theme';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Skeleton from "react-loading-skeleton";
import { useCmsAsset } from 'cogint-cms-toolkit';

export const ABOUT = "ABOUT.md";

export type AboutContentProps = {
    style? : React.CSSProperties,
}

export const AboutContent : FC<AboutContentProps>  = ({
    style,
}) =>{

    const {primaryColor, secondaryColor} = useTheme();
    const alert = useAlert();
    const {Node, value} = useCmsAsset("about-cogint");
    useEffect(()=>{
        
    })
    

    return (

            <div style={{
                width : "70vw",
                display : "grid",
                gridTemplateColumns : "1fr",
                justifyContent : "center",
                justifyItems : "center",
                paddingBottom : "50px",
                ...style
            }}>
            <div style={{
                width : "90%",
                alignSelf : "center",
                textAlign : "left",
                padding : "50px",
                borderRadius : "10px",
                backdropFilter : "invert(5%)"
            }}>
                {!Node && <Skeleton width="100%" count={5}/>}
                {Node && <Node markdown={true} div={true}/>}
            </div></div>

    )

}