import {to} from "await-to-js";

export const getFileText = async (args : {
    owner : string,
    repo : string,
    branch ? : string
    path : string
}) : Promise<string>=> {

    const [error, text] = await to(
        (await fetch(`https://raw.githubusercontent.com/${args.owner}/${args.repo}/${args.branch||"main"}/${args.path}`)).text()
    )

    if(error){
        throw error;
    }

    return text as string;

}