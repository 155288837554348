import { Auth0Controller } from "Controllers/Auth0";
import { Dashboard } from "Controllers/Pages/Dashboard";
import { LandingPage } from "Controllers/Pages/LandingPage";
import React, { FC } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { AppRouter } from "./App/AppRouter";
import { ThemeProvider } from "Views/Theme";
import {Provider as AlertProvider} from "react-alert";
import { AlertTemplate, AlertOptions } from "Controllers/Alert";
import { ArbitraryStoreProvider } from "Models/ArbitraryStore";
import {CmsProvider, getCmsData} from "cogint-cms-toolkit";
import { CmsXrayController } from "Controllers/CmsXray";
import { TermsOfUse } from "Controllers/Pages/TermsOfUse/TermsOfUse";

export type RouterProps = {}

export const Router : FC<RouterProps>  = () =>{

    return (
        <CmsProvider fetch={getCmsData}>
            <CmsXrayController>
                <BrowserRouter>
                    <Auth0Controller>
                        <AlertProvider template={AlertTemplate} {...AlertOptions}>
                            <ThemeProvider>
                                <ArbitraryStoreProvider>
                                    <Routes>
                                        <Route path="/" element={<LandingPage/>}/>
                                        <Route path="terms" element={<TermsOfUse/>}/>
                                        <Route path="/app/*" element={<AppRouter/>}/>
                                        <Route path="/callback"/>
                                    </Routes>
                                </ArbitraryStoreProvider>
                            </ThemeProvider>
                        </AlertProvider>
                    </Auth0Controller>
                </BrowserRouter>
            </CmsXrayController>
        </CmsProvider>
       
    )

}