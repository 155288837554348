import React, {FC, ReactElement} from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export type Auth0ControllerProps = {}

export const Auth0Controller : FC<Auth0ControllerProps>  = ({children}) =>{

    const nav = useNavigate();

    const onRedirectCallback = (appState : any) => {
        nav(appState?.returnTo || window.location.pathname);
    };

    return (

        <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
        redirectUri={window.location.origin + "/callback"}
        useRefreshTokens onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>

    )

}