import React, {FC, ReactElement} from 'react';
import {Routes, Route} from "react-router-dom";
import { DashboardRouter } from './DashboardRouter';
import { EditRouter } from './EditRouter';

export type DomainRouterProps = {}

export const DomainRouter : FC<DomainRouterProps>  = () =>{

    return (

        <Routes>
             <Route path="dashboard/*" element={<DashboardRouter/>}/>
             <Route path="edit/*" element={<EditRouter/>}/>
        </Routes>

    )

}