import { Searchbar, GrowOnEvent, ThroughGlass } from 'cntric-component-library';
import React, { FC, useMemo, useReducer, useState } from 'react';
import { MatchedToggle } from "./MatchedToggle";
import {ItemStoreI} from "cogint-item-providers-react";
import {LineItem, LineItemToggleContextProvider, CommitMethodI, RemoveMethodI, ContainersFC, UsersFC} from "cogint-react-item-namespace";
import { Item} from "cogint-item-fundaments";
import { generate } from 'shortid';
import { ItemI } from 'cogint-item-fundaments';
import { BuiltInWidgets, LeadBuiltInWidgets } from 'Controllers/BuiltInWidgets';
import { filterBySearch } from "Models/lib";
import { RoleController } from 'Controllers/RoleConfig/RoleConfigController';
import { AddContainerController } from 'Controllers/Containers/AddContainerController';
import { useFaunaContext } from 'Models/Fauna';
import { CheckPrivilege, Items } from 'cogint-fauna';
import {query as q, values} from "faunadb";
import { isNumber } from 'underscore';

/**
 * @description
 */
export type SideNavProps = {
    items : ItemStoreI,
    onItemClick ? : (item : ItemI)=>void,
    commit ? : CommitMethodI,
    remove ? : RemoveMethodI,
    Containers ? : ContainersFC,
    Users ? : UsersFC,
    searchString ? : string,
    setSearchString ? : (ss : string)=>void,
    style ? : React.CSSProperties,
    primaryColor ? : React.CSSProperties["color"],
    secondaryColor ? : React.CSSProperties["color"],
    lead ? : boolean
}

export const top = Item({
    _id : "top",
    name : "",
   itemType : "Dir"
});

/**
 * @description 
 */
export const SideNav : FC<SideNavProps>  = ({
    onItemClick,
    items,
    commit,
    remove,
    searchString,
    setSearchString,
    primaryColor,
    secondaryColor,
    style,
    lead = false
}) =>{

    const {faunaClient} = useFaunaContext();
    const canEdit = async (item : ItemI) : Promise<boolean> =>{
       return (isNumber(item._id) || isNumber(parseInt(item._id))) && (faunaClient && await faunaClient.query(CheckPrivilege(q.Ref(Items(), item._id) as values.Ref, "write"))) as unknown as boolean;
        // return true;
    }

    const handleSearch = (searchString : string)=>setSearchString && setSearchString(searchString);
    const filteredItems = filterBySearch(
        items,
        searchString||"",
        (item)=>`${item.name}:${item.itemType}`
    );
   const _filteredItems = Object.keys(filteredItems).sort().reduce((agg, key)=>{
        return {
            ...agg,
            [key] : filteredItems[key]
        }
    }, {})


    return (

             <div style={{
                height: "100%",
                backgroundColor: primaryColor, 
                color: secondaryColor,
                overflowX : "hidden",
                overflowY : "scroll",
                textAlign : "left",
                paddingBottom : "40px",
                ...style
             }}>
                <MatchedToggle/>
                <br/>
                <div style={{
                    height : "40px"
                }}>
                    <Searchbar 
                    primaryColor={secondaryColor}
                    secondaryColor={primaryColor}
                    tertiaryColor={secondaryColor}
                    onChange={handleSearch} size={"sm"} initString={searchString}/>
                </div>
                <br/>
                <LineItemToggleContextProvider>
                    <h6>Built-in items</h6>
                    <div>
                        {useMemo(()=>{
                        
                            return  <GrowOnEvent
                                scale={1.01}>
                                    <ThroughGlass><LineItem
                                annotations={false}
                                style={{
                                    display : "flex",
                                    alignContent : "left",
                                    alignItems : "left",
                                    textAlign : "left"
                                }}
                                key={"~"}
                                size={10}
                                onItemClick={onItemClick}
                                item={top}
                                itemStore={lead ? BuiltInWidgets : LeadBuiltInWidgets}/> </ThroughGlass>
                                </GrowOnEvent>
                            
                            }, [])
                        }
                    </div>
                </LineItemToggleContextProvider>
                <br/>
                <LineItemToggleContextProvider>
                    <div>
                        <h6>Your items</h6>
                        <div style={{
                            display : "flex",
                            flexDirection : "column"
                        }}>
                            <LineItem
                                style={{
                                    display : "flex",
                                    alignContent : "left",
                                    alignItems : "left",
                                    textAlign : "left"
                                }}
                                key={"~"}
                                size={10}
                                onItemClick={onItemClick}
                                item={top}
                                itemStore={_filteredItems}
                                commit={commit}
                                remove={remove}
                                canEdit={canEdit}
                                Containers={AddContainerController}
                                Users={RoleController}/>
                        </div>
                    </div>
                </LineItemToggleContextProvider>
            </div>

       
    )

}