import { CheckPrivilege, DocDocI, DocFromItemRef, Docs, GetPublishedDoc, Items, PublishDoc, UpdateDocDoc } from 'cogint-fauna';
import { ItemI } from 'cogint-item-fundaments';
import { Client, values } from 'faunadb';
import React, {FC, ReactElement} from 'react';
import {query as q} from "faunadb";
import { useFaunaContext } from 'Models/Fauna';
import { useState } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import {DocMainItem, ReactDocI} from "cogint-react-doc-item"
import { useTheme } from 'Views/Theme';
import {useGapi} from "hapi-gapi-toolkit";
import {PublishDocAndSend} from "cogint-fauna";
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import {to} from "await-to-js";
import { useAlert } from 'react-alert';

/**
 * 
 * @param client 
 * @param itemId 
 */
export const getDoc = async (
    client : Client,
    itemId : string
) : Promise<DocDocI | false> =>{

    return await client.query<DocDocI>(
        q.If(
            q.And(
                q.IsRef(q.Ref(Items(), itemId)),
                q.Exists(q.Ref(Items(), itemId))
            ),
            DocFromItemRef(q.Ref(Items(), itemId) as values.Ref),
            false
        )
    ) as DocDocI | false
}

export const publishDoc = async (
    client : Client,
    docId : string,
    compiledContent : string,
    domainId : string,
    alert : any
)=>{

    const [err, val]  = await to(client.query(PublishDocAndSend(
        q.Ref(Docs(), docId) as values.Ref,
        compiledContent,
        domainId
    )));
    console.log(err);
    if(err) alert.error("You do not have permission to publish this document.");

}

export const getPublishedDoc = async (
    client : Client,
    docId : string
) : Promise<string|false>=>{

    return await client.query(GetPublishedDoc(
        q.Ref(Docs(), docId) as values.Ref
    ))
}

export type DocControllerProps = {
    item : ItemI
}

export const DocController : FC<DocControllerProps>  = ({
    item
}) =>{

    const {
        primaryColor,
        secondaryColor,
        tertiaryColor
    } = useTheme();
    const alert = useAlert();

    const {
        client,
        handleSignInClick,
        gapiAuthorized,
        gapiLoaded,
        gapiSigningIn
    } = useGapi();

    const {domainId} = useDomainContext();

    const [dataDoc, setDataDoc] = useState<DocDocI|false>(false);
    const {
        faunaClient
    } = useFaunaContext();
    useEffect(()=>{
        if(faunaClient) getDoc(faunaClient, item._id)
        .then((data)=>setDataDoc(data));
    }, [faunaClient])

    if(!dataDoc) return <div style={{
        display : "grid",
        width : "100%",
        alignContent : 'center',
        justifyContent :"center",
        justifyItems : "center"
    }}><Spinner animation="border"/></div>

    const _dataDoc : ReactDocI = {
        _id : dataDoc.ref.id,
        name : item.name,
        lookup : dataDoc.data.lookup
    }

    const _publishDoc = faunaClient ? async (
        docId : string,
        compiledContent : string
    )=>{
        await publishDoc(faunaClient, docId, compiledContent, domainId, alert)
    } : undefined

    const _getPublishedDoc = faunaClient ? async (
        docId : string,
    )=>{
        return await getPublishedDoc(faunaClient, docId)
    } : undefined

    const canEdit = async () : Promise<boolean>=>{
        return (faunaClient && await faunaClient.query((CheckPrivilege(q.Ref(Items(), item._id) as values.Ref, "write")))) as unknown as boolean;
    };

    return (

        <DocMainItem
        canEdit={canEdit}
        publishDocument={_publishDoc}
        getPublishedDocument={_getPublishedDoc}
        docItem={_dataDoc}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        gapiClient={client}
        gapiAuthorized={gapiAuthorized}
        gapiLoaded={gapiLoaded}
        gapiSigningIn={gapiSigningIn}
        handleSignInClick={handleSignInClick}/>

    )

}