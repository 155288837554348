import { ItemI } from 'cogint-fauna';
import React, {FC, ReactElement} from 'react';

export type NetItemProps = {
    item : ItemI
}

export const NetItem : FC<NetItemProps>  = ({
    item
}) =>{

    return (

        <div>
        

        </div>

    )

}