import { useArbitraryStoreContext } from "Models/ArbitraryStore";
import { Client, query as q, values, PageHelper } from "faunadb";
import { AnalystDashboard } from "Controllers/Pages/AnalystDashboard";
import React, { FC, useEffect, useReducer } from 'react';
import Skeleton from "react-loading-skeleton";
import { ItemDocI, Items } from "cogint-fauna";
import { useFaunaContext } from "Models/Fauna";
import { useItemStore, ItemStoreI } from "cogint-item-providers-react";
import {translateFromFauna} from "cogint-item-fauna-middleware";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "react-alert";
import { GetItems } from "Models/Fauna/Expressions";
import { useDomainContext } from "Models/Domain/DomainContextProvider";
import { useDashboardContext } from "Models/Dashboard/DashboardContextProvider";
import { StakeholderDashboard } from "../StakeholderDashboard/StakeholderDashboard";

/**
 * Loads items for the d.
 * @param client 
 * @returns 
 */
export const loadDashboardItems = async (
    client : Client,
) : Promise<values.Page<ItemDocI>>=>{
    return await client.query(
        GetItems()
    ) as values.Page<ItemDocI>
}

export type DashboardControllerProps = {}

/**
 * @description 
 */
export const DashboardController : FC<DashboardControllerProps>  = () =>{

    const {viewNames} = useDomainContext();
    const {mode} = useDashboardContext();

    const alert = useAlert(); 
    const {
        setItems,
        selectItems
    } = useItemStore();
    
    const {
        faunaClient,
        setFauna
    } = useFaunaContext();
    useEffect(()=>{
       if(faunaClient)
            loadDashboardItems(faunaClient)
            .then((data)=>{
                setItems((items)=>{
                    return data.data.reduce((agg, item)=>{
                            const _item = translateFromFauna(item); 
                            return {
                                ...agg,
                                [_item._id] : _item
                            } 
                        }, items)
                });
                alert.success(`Loaded ${Object.keys(data.data).length} items.`)
            })
            .catch((err: Error)=>{
                alert.error(err.message);
            })
    }, [faunaClient]);

    if(mode === "Analyst") return <AnalystDashboard/>
    else if(mode === "Lead") return <AnalystDashboard/>
    else return <StakeholderDashboard/>
}