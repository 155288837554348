import React, {FC, ReactElement} from 'react';
import { FilesAlt } from 'react-bootstrap-icons';
import {Button} from "cntric-component-library";
import { useTheme } from 'Views/Theme';
import Color from 'color';
import { FilterModes, TimeFilterMode } from 'Controllers/Workspaces/Stakeholder';

export type IntelligenceDataToggleProps = {
    toggled ? : boolean,

}

export const IntelligenceDataToggle : FC<IntelligenceDataToggleProps> = ({
    children
})=>{
    return (
        <Button>

        </Button>
    )
}


export type IntelligenceDataPieChartInnerProps = {
    data : any[]
    size ? : number,
    onSelect ? : (mode : TimeFilterMode)=>void,
    mode ? : TimeFilterMode
}

export const IntelligenceDataPieChartInner : FC<IntelligenceDataPieChartInnerProps>  = ({
    data,
    size = 300,
    mode,
    onSelect
}) =>{

    

    const {secondaryColor, tertiaryColor} = useTheme();

    return (

        <div style={{
            color : tertiaryColor
        }}>
            <div style={{
                padding : "5px"
            }}>
                <FilesAlt size={size}/>
            </div>
            <h5 style={{
                margin : 0
            }}>Intelligence Data</h5>
            <div style={{
                display : "flex"
            }}>
                {FilterModes.map((givenMode)=>{
                    return (
                        <Button 
                        onClick={()=>onSelect && onSelect(givenMode)}
                        style={{
                            border : "none",
                            textDecoration : mode === givenMode ? "underline" : "none"
                        }}
                        primaryColor={tertiaryColor}
                        secondaryColor={secondaryColor}>
                            {givenMode}
                        </Button>
                    )
                })}
            </div>
            <span style={{
                fontSize : "12px",
                color : Color(tertiaryColor).lighten(.8).hex()
            }}>Filter by date delivered.</span>
            <br/>
            {data.length < 1 &&<span style={{
                fontSize : "12px",
                color : Color(tertiaryColor).lighten(.8).hex()
            }}>You have not received any data.</span>}
        </div>

    )

}