import React, {FC, ReactElement} from 'react';
import { useState } from "react";
import { useTheme } from "Views/Theme";
import {GetMySentReceipts} from "cogint-fauna";
import { useFaunaContext } from "Models/Fauna";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {query as q} from "faunadb";
import { ReceiptlikeDoc } from "cogint-fauna/dist/cjs/CogintFaunaClient/Objects/Primitives/Receipt/Receipt";
import { IntelligenceDataPieChart } from '../IntelligenceDataPieChart';
import { IntelligenceDataGraph } from '../IntelligenceDataGraph';
import { TimeFilterMode, DateMap } from 'Controllers/Workspaces/Stakeholder';
  
export const modeFilter =  (data : ReceiptlikeDoc[], mode : TimeFilterMode) : ReceiptlikeDoc[]=>{
    
    return data.filter((receipt)=>{
        const date = new Date(receipt.ts);
        return date < new Date () && date > DateMap[mode]();
    });
}

export type IntelligencePieAndChartProps = {
    data : ReceiptlikeDoc[],
    filteredData : ReceiptlikeDoc[],
    filterData : (filter : (data : ReceiptlikeDoc[])=>ReceiptlikeDoc[])=>void,
    mode : TimeFilterMode,
    setMode : (mode : TimeFilterMode)=>void
}

export const IntelligencePieAndChart : FC<IntelligencePieAndChartProps>  = ({
    data,
    filteredData,
    filterData,
    mode,
    setMode
}) =>{


    return (

        <div style={{
            display : 'grid',
            gridTemplateColumns : "1fr 1fr"
        }}>
            <div style={{
                display : "grid",
                alignItems : "center",
                alignContent : "center",
                justifyItems : "center",
                justifyContent : "center"
            }}>
                <IntelligenceDataPieChart 
                mode={mode} setMode={setMode}
                data={filteredData} filterData={filterData}/>
            </div>
            <div style={{
                display : "grid",
                alignItems : "center",
                alignContent : "center",
                justifyItems : "center",
                justifyContent : "center"
            }}>
                <IntelligenceDataGraph data={filteredData} mode={mode}/>
            </div>
        </div>

    )

}