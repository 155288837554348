import { MyIdentity, UserDocI } from 'cogint-fauna';
import { useFaunaContext } from 'Models/Fauna';
import React, {FC, ReactElement} from 'react';
import { createContext } from 'react';
import { useEffect } from 'react';
import {useParams} from "react-router-dom";
import {query as q, values} from "faunadb";
import { useReducer } from 'react';
import { useContext } from 'react';
import { useTheme } from 'Views/Theme';

export type MyIdentityContext = {
    /** the current user's identity. */
    myIdentity : UserDocI | undefined,
    /** whether the myIdentity context has loaded. */
    loaded : boolean,
    /** sets the MyIdentityContext state. */
    dispatch : React.Dispatch<(state: MyIdentityContext) => MyIdentityContext>
    /** forces the myIdentity context to refresh. */
    refresh : ()=>void
}

/**
 * By default the myIdentity name should be root
 * and the views should be empty.
 */
export const DefaultMyIdentityContext : MyIdentityContext = {
    myIdentity : undefined,
    loaded : false,
    dispatch : ()=>{},
    refresh : ()=>{} 
} 

export const MyIdentityCtx = createContext(DefaultMyIdentityContext);

/**
 * Reduces the state of a myIdentity with a setter function.
 * @param state is the state of the myIdentity before the reductino.
 * @param set is a function that sets the state of the myIdentity.
 * @returns the new state of the myIdentity.
 */
export const myIdentityReducer = (state : MyIdentityContext, set : (state : MyIdentityContext)=>MyIdentityContext)=>set(state);

export type MyIdentityContextProps = {
    handleError ? : (err : Error)=>void
}

/**
 * Provides access to MyIdentityContext. Handles view fetching for MyIdentityContext.
 * @param param0 
 * @returns 
 */
export const MyIdentityContextProvider : FC<MyIdentityContextProps>  = ({children, handleError}) =>{

    const [state, dispatch] = useReducer(
        myIdentityReducer,
        DefaultMyIdentityContext
    );

    const [tick, refresh] = useReducer(x=>x+1, 0);

    const {faunaClient} = useFaunaContext();
    const theme = useTheme();
    useEffect(()=>{

        // we should look to make this one round trip
        faunaClient && faunaClient.query<UserDocI>(q.Get(MyIdentity()))
        .then((data)=>{
            
            dispatch((state)=>({
                ...state,
                myIdentity : data
            }));
        }).catch((err)=>{
            
            dispatch((state)=>({
                ...state,
            }))
        });

        }, [faunaClient, tick])

    return (

        <MyIdentityCtx.Provider value={{
            ...state,
            dispatch : dispatch,
            refresh : refresh
        }}>
            {children}
        </MyIdentityCtx.Provider>

    )

}

/**
 * Gives read-only access to myIdentity context.
 * @returns the current myIdentity context.
 */
export const useMyIdentityContext = () : Readonly<MyIdentityContext> =>{
    const context = useContext(MyIdentityCtx);
    if(!context) throw new Error("useMyIdentityContext must be called from within a context provider.");
    return context;
}