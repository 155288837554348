import { LoadingPage } from 'Controllers/Pages/LoadingPage';
import { useDomainContext } from 'Models/Domain/DomainContextProvider';
import { useToSContext } from 'Models/ToS/ToSContextProvider';
import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Asserts whether the current pathname is the editTos path.
 * @param path 
 * @returns 
 */
export const isEditToS = (path : string)=>{
    return path.match(/app\/d\/\d\/edit\/tos/) !== null;
}

export type ToSGuardControllerProps = {}

export const ToSGuardController : FC<ToSGuardControllerProps>  = ({children}) =>{

    const {agreed, loaded, refreshCount} = useToSContext();
    const {domainId} = useDomainContext();
    const nav = useNavigate();
    useEffect(()=>{
        if(!agreed && loaded && !isEditToS(window.location.pathname)) nav(`/app/d/${domainId}/edit/tos?returnTo=${encodeURI(window.location.pathname + window.location.search)}`)
    })

    return (

        loaded ? <>{children}</> : <LoadingPage/>

    )

}