import { DomainContextProvider } from 'Models/Domain/DomainContextProvider';
import React, {FC, ReactElement} from 'react';
import { useAlert } from 'react-alert';

export type DomainContextControllerProps = {}

/**
 * Controller for DomainContext.
 * @param param0 
 * @returns 
 */
export const DomainContextController : FC<DomainContextControllerProps>  = ({children}) =>{

    const alert = useAlert();

    const handleError  = (err : Error)=>{
        
        alert.error(err.message);
    }
    

    return (

        <DomainContextProvider handleError={handleError}> 
            {children}
        </DomainContextProvider>

    )

}