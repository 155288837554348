import { ItemI } from 'cogint-item-fundaments';
import React, {FC, ReactElement} from 'react';
import { DocItem } from './DocItem';
import { NetItem } from './NetItem';

export type StakeholderItemRouterProps = {
    item : ItemI
}

export const StakeholderItemRouter : FC<StakeholderItemRouterProps>  = ({
    item
}) =>{

    if(item.itemType === "Doc") return (
        <DocItem item={item}/>
    ); 
    else if(item.itemType === "Net") return (
        <NetItem item={item}/>
    )

    return (
        <div>
            {item.name}
        </div>
    )

}