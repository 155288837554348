import { useItemStore } from "cogint-item-providers-react";
import React, {FC, ReactElement} from 'react';
import { PieChart, Pie, Label, Tooltip } from 'recharts';
import {FilesAlt} from "react-bootstrap-icons";
import { IntelligenceDataPieChartInner} from "./IntelligenceDataPieChartInner";
import { useState } from "react";
import { useTheme } from "Views/Theme";
import {GetMySentReceipts} from "cogint-fauna";
import { useFaunaContext } from "Models/Fauna";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {query as q} from "faunadb";
import { ReceiptlikeDoc } from "cogint-fauna/dist/cjs/CogintFaunaClient/Objects/Primitives/Receipt/Receipt";
import {TimeFilterMode } from "Controllers/Workspaces/Stakeholder";
import { modeFilter } from "../IntelligencePieAndChart/IntelligencePieAndChart";

export type IntelligenceDataPieChartProps = {
    height ? : number,
    width ? : number,
    data : ReceiptlikeDoc[],
    mode ? : TimeFilterMode,
    setMode ? : (mode : TimeFilterMode)=>void
    filterData : (filter : (data : ReceiptlikeDoc[])=>ReceiptlikeDoc[])=>void
}

export const IntelligenceDataPieChart : FC<IntelligenceDataPieChartProps>  = ({
  mode = "1W",
  setMode,
  data,
  filterData,
  children,
  height = 300,
  width = 300
}) =>{

    const {secondaryColor, tertiaryColor} = useTheme();
    const {selectItems} = useItemStore();
    const items = selectItems();

   
    const handleSelect = (mode : TimeFilterMode)=>{
      setMode && setMode(mode);
    };
    useEffect(()=>{
      
      filterData((data)=>{
        
        return modeFilter(data, mode)
      });
    }, [mode, filterData !== undefined])

    const _data = data.reduce((agg, receipt)=>{
      
      return {
        ...agg,
        ...items[receipt.data.item.id] ? {
          ...agg[items[receipt.data.item.id].itemType] !== undefined ? {
            [items[receipt.data.item.id].itemType] : agg[items[receipt.data.item.id].itemType] + 1
          } : {
            [items[receipt.data.item.id].itemType] : 1
          }
        } : {}
      }
    }, {} as {[key : string] : number});

    const __data = Object.keys(_data).map((key)=>{
      return {name : key, amount : _data[key]}
    }).reverse();

    return (

      <div style={{
        position : "relative",
        height : height,
        width : width
      }}>
        <PieChart width={width} height={height}>
          <Pie 
          innerRadius={(height/2) * .75} 
          outerRadius={height/2} 
          nameKey="name" 
          data={data} 
          dataKey="amount" 
          fill={secondaryColor}
          stroke={tertiaryColor}/>
          <Tooltip/>
        </PieChart>
        <div style={{
          position : "absolute",
          height : "100%",
          width : "100%",
          display : "grid",
          alignContent : "center",
          justifyContent : "center",
          left : 0,
          top : 0
        }}>
          <IntelligenceDataPieChartInner 
          data={__data}
          mode={mode}
          onSelect={handleSelect}
          size={height * .2}/>
        </div>
      </div>
    );

}